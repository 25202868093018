.c-gradient-separator {
	position:absolute;
	bottom:0px;
	display:block;
	width:2px;
	height:90%;
	margin:auto 0;
	background: -moz-linear-gradient(top, rgba($grey,0) 0%, rgba($grey,0) 1%, rgba($grey,0.3) 50%, rgba($grey,0) 100%);
	background: -webkit-linear-gradient(top, rgba($grey,0) 0%,rgba($grey,0) 1%,rgba($grey,0.3) 50%,rgba($grey,0) 100%);
	background: linear-gradient(to bottom, rgba($grey,0) 0%,rgba($grey,0) 1%,rgba($grey,0.3) 50%,rgba($grey,0) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00292929', endColorstr='#00292929',GradientType=0 );

	@include mq('small') {
		display:none;
	}
}

li > .c-gradient-separator:first-child, 
div > .c-gradient-separator:first-child {
	left:0px;
}

li > .c-gradient-separator:last-child,
div > .c-gradient-separator:last-child {
	right:0px;
}