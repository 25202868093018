.c-deco-lines {
	position: absolute;
	right: -10px;
	bottom: 30px;
	max-width: 20px;

	img {
		width:100%;
	}
}
