.c-about__team {
	
	&-photo {
		height:600px;
		background-position:center;
		background-repeat:no-repeat;
		background-size:cover;

		@include mq('large') {
			height: 400px;
		}

		@include mq('medium') {
			height: 250px;
		}

		@include mq('small') {
			height: 200px;
		}
	}

	&-list {
		background:$black;
		padding:60px;
		
		position:relative;

		@include mq('xsmall') {
			padding:60px 30px;
		}

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:cover;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 1;
			transform:rotate(180deg);
			opacity: 0.5;
		}
	}

	&-order,
	h2,
	ul {
		position:relative;
		z-index: 2;
	}

	&-order {
		font-family: $p-black;
		font-size:1.4rem;
		line-height: 1.4rem;
		color:$grey;
		text-transform: uppercase;

		@include mq('small') {
			display:none;
		}
	}

	h2 {
		font-family: $p-black;
		font-size:3.2rem;
		line-height: 3.2rem;
		color:$white;
		text-transform: uppercase;
		text-align: center;
	}

	ul {
		list-style-type:none;
		padding:0px;
		display: flex;
		flex-flow:row wrap;
		justify-content: space-between;
		align-items:flex-start;
		margin-top:60px;

		li {
			width:25%;
			text-align: center;
			margin-bottom:40px;

			@include mq('small') {
				width:50%;
			}

			@include mq('xsmall') {
				width:100%;
			}

			h3 {
				text-transform:uppercase;
				font-family: $p-black;
				color:$red;
				font-size:1.8rem;
				line-height: 1.8rem;
				margin-bottom:8px;
			}

			p {
				margin:0px;
				text-transform:uppercase;
				font-size:1.4rem;
				line-height:1.4rem;
			}
		}
	}
}

.c-about__grid {
	height:600px;
	display:flex;
	flex-flow:row wrap;

	@include mq('large') {
		height:450px;
	}
	@include mq('medium') {
		height:350px;
	}
	@include mq('small') {
		height:250px;
	}
	@include mq('xsmall') {
		display: none;
	}


	& > div {
		height:100%;
	}

	&-one-third {
		width:30%;
	}

	&-two-third {
		width:70%;

	}

	&-large,
	&-small {
		background-size:cover;
		background-repeat:no-repeat;
		background-position:top center;;
	}

	&-large {
		width:100%;
		height:100%;
	}

	&-small {
		width:100%;
		height:50%;
	}
}

.c-about__historique {
	padding-top:0px;

	&-container {
		background:$grey-light;
		padding:100px 60px;

		@include mq('small') {
			padding:60px 20px;
		}
	}

	&-item {
		display:flex;
		flex-flow:row wrap;
		justify-content: space-between;
		margin-bottom:40px;

		& > div {
			width:50%;

			@include mq('medium') {
				width:100%;
			}
		}
	}

	&-boxed {
		display:flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:center;
	}

	&-content {
		max-width:400px;

		@include mq('medium') {
			max-width:100%;
			display:flex;
			flex-flow:row wrap;
			align-items: center;
			justify-content: space-between;
			padding:50px 0px;
		}

		& > * {

			@include mq('medium') {
				width:50%;
			}

			@include mq('small') {
				width:100%;
				text-align: center;
			}
		}
	}

	&-year {
		font-family:$p-black;
		font-size:15rem;
		line-height:15rem;
		color:$red;

		@include mq('xsmall') {
			font-size:11rem;
			line-height: 12rem;
		}
	}

	&-image {
		background-size:cover;
		background-repeat:no-repeat;
		min-height:320px;
		background-position:center;

		@include mq('medium') {
			order:-1;
			min-height:400px;
		}

		@include mq('small') {
			min-height:300px;
		}

		@include mq('xsmall') {
			min-height:200px;
		}
	}

	p {
		color:$grey;
		font-size:1.8rem;
		line-height: 2.4rem;
	}
	
	h2 {
		text-align: center;
		font-family:$p-black;
		font-size:3.2rem;
		line-height:3.2rem;
		text-transform:uppercase;
		max-width:550px;
		margin:0 auto 100px;

		@include mq('small') {
			margin-bottom:50px;
		}
	}
}