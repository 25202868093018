.c-subtitle--red,
.c-subtitle--black {
	@extend .c-subtitle
}

.c-subtitle {
	font-family:$p-black;
	font-size:1.5rem;
	line-height:1.8rem;
	text-transform:uppercase;
	margin:0px 0px 8px;

	@include mq('small') {
		font-size:2.5rem;
		line-height: 2.8rem;
	}
}

.c-subtitle--red {
	color:$red;
}

.c-subtitle--black {
	color:$black;
}