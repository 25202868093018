/* =======================================================================
Core
========================================================================== */
/* =======================================================================
Responsive
========================================================================== */
/* =======================================================================
Font-face
========================================================================== */
@font-face {
  font-family: 'proxima-black';
  src: url("../fonts/proxima-nova/proxima-nova_black.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima-nova/proxima-nova_black.otf") format("opentype"), url("../fonts/proxima-nova/proxima-nova_black.ttf") format("truetype");
}

@font-face {
  font-family: 'proxima-bold';
  src: url("../fonts/proxima-nova/proxima-nova_bold.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima-nova/proxima-nova_bold.otf") format("opentype"), url("../fonts/proxima-nova/proxima-nova_bold.ttf") format("truetype");
}

@font-face {
  font-family: 'proxima-regular';
  src: url("../fonts/proxima-nova/proxima-nova_regular.eot?#iefix") format("embedded-opentype"), url("../fonts/proxima-nova/proxima-nova_regular.otf") format("opentype"), url("../fonts/proxima-nova/proxima-nova_regular.ttf") format("truetype"), url("../fonts/proxima-nova/proxima-nova_regular-webfont.woff2") format("woff2"), url("../fonts/proxima-nova/proxima-nova_regular-webfont.woff") format("woff");
}

/* =======================================================================
Variables
========================================================================== */
/* =======================================================================
Helpers
========================================================================== */
/* line 5, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-centered {
  text-align: center;
}

/* line 9, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-clearfix:before,
.h-clearfix:after {
  content: " ";
  /* 1 */
  display: table;
  /* 2 */
}

/* line 15, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-clearfix:after {
  clear: both;
}

/* line 19, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-clearfix {
  *zoom: 1;
}

/* line 23, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-unselectable {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* line 32, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-visuallyhidden {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

/* line 43, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-embed-responsive {
  position: relative;
  display: block;
  height: 0;
  padding: 0;
  overflow: hidden;
}

/* line 51, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100%;
  width: 100%;
  border: 0;
}

/* line 64, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-16by9 {
  padding-bottom: 56.25%;
}

/* line 68, public/wp-content/themes/cpmrs/assets/src/scss/core/_helpers.scss */
.h-embed-responsive-4by3 {
  padding-bottom: 75%;
}

/* =======================================================================
Global
========================================================================== */
/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
html {
  font-size: 10px;
}

@media screen and (min-width: 1400px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
  html {
    font-size: 12px;
  }
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
  html {
    font-size: 8px;
  }
}

@media (max-width: 550px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
  html {
    font-size: 7px;
  }
}

/* line 17, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
* {
  box-sizing: border-box;
}

/* line 21, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
html,
button,
input,
select,
textarea {
  color: #333;
  font-family: Arial, Verdana, sans-serif;
}

/* line 30, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
body {
  background: #fff;
  font-size: 16px;
  line-height: 1.4;
  margin: 0px;
}

/* line 37, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
html, body {
  height: 100%;
}

/* line 41, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
header, section, footer {
  padding-left: 20px;
  padding-right: 20px;
  box-sizing: border-box;
}

/* line 47, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
section, footer {
  padding-top: 20px;
}

/* line 51, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
h1, h2, h3, h4, h5, h6, a, span, div, li {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* line 56, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
p {
  font-family: "proxima-regular";
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #fcfcfc;
}

@media (max-width: 767px) {
  /* line 56, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
  p {
    font-size: 2rem;
    line-height: 2.4rem;
  }
}

/* line 68, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
a {
  color: #ff0000;
  text-decoration: none;
}

/* line 71, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
a:hover {
  color: #990000;
}

/* line 72, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
a:focus, a:active {
  color: #330000;
}

/* line 76, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
img {
  vertical-align: middle;
  max-width: 100%;
}

/* line 78, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
hr {
  display: block;
  height: 1px;
  border: 0;
  border-top: 1px solid #ccc;
  margin: 1em 0;
  padding: 0;
}

/* line 87, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

/* line 92, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
textarea {
  resize: vertical;
}

/* line 97, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
::-moz-selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 103, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
::selection {
  background: black;
  color: white;
  text-shadow: none;
}

/* line 110, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
::-webkit-input-placeholder {
  /* WebKit browsers */
  color: #fcfcfc;
  opacity: 1;
}

/* line 114, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
:-moz-placeholder {
  /* Mozilla Firefox 4 to 18 */
  color: #fcfcfc;
  opacity: 1;
}

/* line 118, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
::-moz-placeholder {
  /* Mozilla Firefox 19+ */
  color: #fcfcfc;
  opacity: 1;
}

/* line 122, public/wp-content/themes/cpmrs/assets/src/scss/global/_base.scss */
:-ms-input-placeholder {
  /* Internet Explorer 10+ */
  color: #fcfcfc;
  opacity: 1;
}

/* =======================================================================
Forms
========================================================================== */
/* =======================================================================
Layout
========================================================================== */
/* line 5, public/wp-content/themes/cpmrs/assets/src/scss/global/_layout.scss */
.l-6 {
  width: 50%;
  float: left;
}

/* =======================================================================
Navigation
========================================================================== */
/* =======================================================================
Components
========================================================================== */
/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header {
  background-color: #171717;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  height: 110px;
  position: relative;
  overflow: hidden;
  margin: 20px 20px 0px;
  padding-right: 0px;
}

@media (max-width: 550px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header {
    flex-flow: column wrap;
    height: auto;
    padding-left: 0px;
  }
}

/* line 19, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: 100%;
  background-position: bottom -250px right;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  margin: 0 0 0 auto;
  pointer-events: none;
  transform: translateX(10%);
  max-width: 1000px;
}

/* line 35, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header > div {
  position: relative;
  z-index: 1;
}

@media (max-width: 550px) {
  /* line 35, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header > div {
    width: 100%;
  }
}

/* line 44, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__logo {
  flex-grow: 1;
}

@media (max-width: 550px) {
  /* line 44, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__logo {
    padding: 40px 40px 20px;
    text-align: left;
    margin-bottom: 10px;
  }
}

/* line 53, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__logo img {
  max-width: 220px;
  width: 100%;
  margin-left: 20px;
  margin-top: 20px;
}

@media (max-width: 550px) {
  /* line 53, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__logo img {
    margin-left: 0px;
    max-width: 80%;
  }
}

@media (max-width: 992px) {
  /* line 66, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__menu {
    display: none;
  }
}

/* line 72, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__menu ul {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  list-style-type: none;
  padding: 0px;
  margin: 0px;
}

/* line 81, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__menu ul > li > a {
  font-family: "proxima-black";
  font-size: 1.3rem;
  line-height: 2rem;
  color: #989898;
  text-transform: uppercase;
  margin: 0 2.5rem;
  transition: color 300ms ease-in-out;
  position: relative;
}

@media (max-width: 1199px) {
  /* line 81, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__menu ul > li > a {
    margin: 0 1.5rem;
  }
}

/* line 95, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__menu ul > li > a:after {
  content: '';
  display: block;
  width: 0%;
  height: 3px;
  position: absolute;
  bottom: -10px;
  left: 0px;
  background: #d72b38;
  transition: all 300ms ease-in-out;
}

/* line 108, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__menu ul > li:hover > a,
.c-header__menu ul > li.current_page_item > a {
  color: #fcfcfc;
}

/* line 112, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__menu ul > li:hover > a:after,
.c-header__menu ul > li.current_page_item > a:after {
  width: 100%;
}

/* line 119, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__info {
  height: 100%;
  margin-left: 2.5rem;
  display: flex;
  flex-flow: column wrap;
  justify-content: space-around;
  align-items: center;
}

@media (max-width: 550px) {
  /* line 119, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__info {
    margin-left: 0px;
  }
}

/* line 131, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__info > div {
  width: 100%;
  height: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  padding: 1.5rem 2rem;
  text-align: center;
}

@media (max-width: 550px) {
  /* line 131, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__info > div {
    align-items: flex-start;
  }
}

/* line 148, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__financing, .c-header__phone {
  font-family: "proxima-black";
  color: #fcfcfc;
}

/* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__financing {
  background: #d72b38;
  font-size: 1rem;
  line-height: 1.8rem;
  text-transform: uppercase;
}

@media (max-width: 992px) {
  /* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__financing {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@media (max-width: 767px) {
  /* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__financing {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

@media (max-width: 550px) {
  /* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__financing {
    display: none !important;
  }
}

/* line 175, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__phone {
  border-left: 3px solid #d72b38;
  font-size: 1.4rem;
  line-height: 1.8rem;
}

@media (max-width: 992px) {
  /* line 175, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__phone {
    font-size: 1.4rem;
    line-height: 2rem;
  }
}

@media (max-width: 767px) {
  /* line 175, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__phone {
    font-size: 2.4rem;
    line-height: 2.4rem;
  }
}

@media (max-width: 550px) {
  /* line 175, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
  .c-header__phone {
    border-left: 0px;
    padding: 20px 55px 20px 40px !important;
  }
}

/* line 195, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__phone a {
  color: #fcfcfc;
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  align-items: center;
}

/* line 203, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-header.scss */
.c-header__phone i {
  color: #d72b38;
  font-size: 1.8rem;
  margin-right: 5px;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold {
  height: calc(100% - 170px);
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  padding-left: 20px;
}

@media (max-width: 992px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold {
    height: auto;
  }
}

/* line 13, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold:before {
  content: '';
  position: absolute;
  top: 20px;
  bottom: 0;
  left: 20px;
  width: 300px;
  display: block;
  height: calc(100% + 60px);
  background-color: #171717;
}

@media (max-width: 992px) {
  /* line 13, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold:before {
    display: none;
  }
}

/* line 29, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold:after {
  content: "";
  position: absolute;
  bottom: 0;
  top: 20px;
  left: 20px;
  right: 0;
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  max-width: 300px;
  height: calc(100% + 60px);
  opacity: 0.4;
}

@media (max-width: 992px) {
  /* line 29, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold:after {
    display: none;
  }
}

/* line 49, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__col-left, .c-fold__col-right {
  height: 100%;
}

/* line 58, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__col-left {
  width: calc(48% - 10px);
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: flex-start;
  margin-right: 10px;
}

@media (max-width: 992px) {
  /* line 58, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__col-left {
    width: 100%;
    margin-right: 0px;
    height: 400px;
  }
}

/* line 73, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__col-right {
  width: calc(52% - 10px);
  margin-left: 10px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 992px) {
  /* line 73, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__col-right {
    justify-content: space-between;
    margin-left: 0px;
    width: 100%;
    height: 50%;
  }
}

/* line 89, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: flex-start;
  padding: 40px 60px 0px;
  cursor: pointer;
}

@media (max-width: 992px) {
  /* line 89, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__about {
    padding-top: 80px;
  }
}

@media (max-width: 550px) {
  /* line 89, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__about {
    padding: 40px 30px 0px;
  }
}

/* line 114, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 2;
  pointer-events: none;
  transition: background 300ms ease-in-out;
}

/* line 129, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about:hover:before {
  background: rgba(0, 0, 0, 0.8);
}

/* line 135, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about:hover .c-button:after, .c-fold__about:hover .c-button--lift-up:after, .c-fold__about:hover
.c-button--rectangle:after, .c-fold__about:hover
.c-button--fournisseurs:after, .c-fold__about:hover
.c-button--footer:after {
  height: 100%;
  transition-delay: 0ms;
}

/* line 140, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about:hover .c-button i, .c-fold__about:hover .c-button--lift-up i, .c-fold__about:hover
.c-button--rectangle i, .c-fold__about:hover
.c-button--fournisseurs i, .c-fold__about:hover
.c-button--footer i {
  margin-left: 30px;
  transition-delay: 300ms;
  opacity: 1;
}

/* line 148, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about > div {
  z-index: 2;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
}

/* line 156, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about .c-fold__content {
  flex-grow: 1;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
}

@media (max-width: 992px) {
  /* line 156, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__about .c-fold__content {
    text-align: center;
    align-items: center;
  }
}

/* line 171, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about h2, .c-fold__about span, .c-fold__about p {
  z-index: 3;
}

/* line 175, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about h2 {
  max-width: 500px;
  font-size: 4.5rem;
  line-height: 5rem;
}

@media (max-width: 550px) {
  /* line 175, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__about h2 {
    font-size: 4.2rem;
    line-height: 4.5rem;
  }
}

/* line 186, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__about p {
  max-width: 450px;
}

@media (max-width: 992px) {
  /* line 186, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__about p {
    margin-bottom: 40px;
  }
}

/* line 195, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo {
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-start;
  align-items: flex-start;
  background: #d72b38;
  padding: 25px 30px;
  position: relative;
  z-index: 9;
  width: 100%;
  transition: all 300ms ease-in-out;
}

@media (max-width: 992px) {
  /* line 195, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo {
    margin-top: 20px;
  }
}

@media (max-width: 767px) {
  /* line 213, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo img {
    max-width: 180px !important;
  }
}

@media (max-width: 550px) {
  /* line 195, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo {
    align-items: center;
  }
  /* line 221, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo img {
    display: none;
  }
}

/* line 226, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo:hover {
  background: #ae212c;
}

/* line 230, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: 100%;
  background-position: top left;
  background-repeat: no-repeat;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  transform: scaleX(-1);
}

/* line 246, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo:after {
  content: "";
  position: absolute;
  top: 40px;
  right: 0px;
  display: block;
  height: 30px;
  width: 155px;
  background: #171717;
  transform: rotate(-45deg) skewX(45deg);
}

@media (max-width: 550px) {
  /* line 246, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo:after {
    display: none;
  }
}

/* line 262, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo h2, .c-fold__promo span {
  position: relative;
  z-index: 2;
}

/* line 267, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo h2 {
  max-width: 300px;
  font-size: 3rem;
  line-height: 3rem;
}

@media (max-width: 767px) {
  /* line 267, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo h2 {
    max-width: 200px;
  }
}

@media (max-width: 550px) {
  /* line 267, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__promo h2 {
    margin: 0 auto;
    text-align: center;
  }
}

/* line 282, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__promo img {
  position: absolute;
  right: 20px;
  top: 40px;
  bottom: 0;
  margin: auto 0;
  max-width: 210px;
  z-index: 3;
  backface-visibility: hidden;
}

/* line 294, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold h2, .c-fold h3 {
  font-family: "proxima-black";
  color: #fcfcfc;
  margin-top: 0px;
  margin-bottom: 0px;
  text-transform: uppercase;
}

/* line 303, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold h3 {
  font-size: 1.8rem;
  line-height: 2.6rem;
}

/* line 308, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial, .c-fold__products {
  width: calc(50% - 10px);
  height: 100%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  padding: 30px;
  text-align: center;
}

@media (max-width: 992px) {
  /* line 308, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__commercial, .c-fold__products {
    height: auto !important;
    height: 300px !important;
  }
}

@media (max-width: 767px) {
  /* line 308, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__commercial, .c-fold__products {
    width: 100%;
    margin-left: 0px !important;
    margin-right: 0px !important;
    height: 250px !important;
  }
}

/* line 337, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial:hover h3:after, .c-fold__products:hover h3:after {
  transform: translateX(0px);
  opacity: 1;
}

/* line 344, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial h3, .c-fold__products h3 {
  max-width: 220px;
  position: relative;
}

@media (max-width: 550px) {
  /* line 344, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__commercial h3, .c-fold__products h3 {
    max-width: 100%;
  }
}

/* line 352, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial h3:after, .c-fold__products h3:after {
  content: "\f178";
  display: block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #d72b38;
  position: absolute;
  bottom: -30px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  opacity: 0;
  transform: translateX(-25px);
  transition: all 300ms ease-in-out;
}

/* line 374, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial {
  margin-right: 10px;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 80% 0px;
  position: relative;
  cursor: pointer;
}

@media (max-width: 992px) {
  /* line 374, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__commercial {
    background-position: center;
  }
}

/* line 389, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial:hover:before {
  background: rgba(0, 0, 0, 0.8);
}

/* line 394, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial:before {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.65);
  z-index: 2;
  pointer-events: none;
  transition: background 300ms ease-in-out;
}

/* line 407, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__commercial h3, .c-fold__commercial span {
  position: relative;
  z-index: 3;
}

/* line 413, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__products {
  margin-left: 10px;
  background-color: #171717;
  position: relative;
}

/* line 418, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__products:before {
  content: '';
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  background-position: center right;
  background-repeat: no-repeat;
  opacity: 0.5;
}

/* line 432, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__products h3, .c-fold__products span {
  position: relative;
  z-index: 3;
}

/* line 439, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__partners {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  opacity: 0.4;
  pointer-events: none;
}

@media (max-width: 992px) {
  /* line 439, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
  .c-fold__partners {
    display: none;
  }
}

/* line 457, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__partners img {
  height: 15px;
  margin: 8px 5px;
  opacity: 1;
}

/* line 463, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__partners > div {
  max-width: 230px;
}

/* line 467, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__partners > .top-row {
  margin-top: 270px;
  height: 100px;
}

/* line 472, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-fold.scss */
.c-fold__partners > .bottom-row {
  margin-top: 20px;
}

/* line 6, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-subtitle.scss */
.c-subtitle, .c-subtitle--red,
.c-subtitle--black {
  font-family: "proxima-black";
  font-size: 1.5rem;
  line-height: 1.8rem;
  text-transform: uppercase;
  margin: 0px 0px 8px;
}

@media (max-width: 767px) {
  /* line 6, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-subtitle.scss */
  .c-subtitle, .c-subtitle--red,
  .c-subtitle--black {
    font-size: 2.5rem;
    line-height: 2.8rem;
  }
}

/* line 19, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-subtitle.scss */
.c-subtitle--red {
  color: #d72b38;
}

/* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-subtitle.scss */
.c-subtitle--black {
  color: #171717;
}

/* line 8, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button, .c-button--lift-up,
.c-button--rectangle,
.c-button--fournisseurs,
.c-button--footer {
  font-family: "proxima-black" !important;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #fcfcfc;
  text-transform: uppercase;
  position: relative;
  text-align: center;
  z-index: 1;
  cursor: pointer;
  display: inline-flex;
  flex-flow: row wrap;
  align-items: center;
}

/* line 22, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button:visited, .c-button--lift-up:visited,
.c-button--rectangle:visited,
.c-button--fournisseurs:visited,
.c-button--footer:visited {
  color: #fff;
}

/* line 26, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--lift-up {
  display: block;
  width: 100%;
  padding: 30px;
}

@media (max-width: 550px) {
  /* line 26, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
  .c-button--lift-up {
    padding: 15px 20px;
  }
}

/* line 35, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--lift-up:after {
  content: '';
  position: absolute;
  bottom: 0px;
  width: 100%;
  height: 10px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  background: #d72b38;
  z-index: -1;
  transition: all 300ms ease-in-out;
  transition-delay: 300ms;
}

/* line 50, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--lift-up i {
  transition: all 300ms ease-in-out;
  font-size: 1.8rem;
  transition-delay: 0ms;
  color: #171717;
  opacity: 0;
  margin-left: 10px;
  margin-top: -2px;
}

@media (max-width: 767px) {
  /* line 50, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
  .c-button--lift-up i {
    display: none;
  }
}

/* line 65, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--rectangle {
  background: #171717;
  padding: 18px 35px;
  margin-top: 25px;
}

/* line 70, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--rectangle i {
  color: #d72b38;
  margin-left: 10px;
  transition: all 300ms ease-in-out;
  margin-top: -2px;
}

/* line 77, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--rectangle:hover {
  color: #fcfcfc;
}

/* line 80, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--rectangle:hover i {
  margin-left: 20px;
}

/* line 86, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--fournisseurs {
  padding: 20px 30px;
  margin: 10px auto 0px;
  color: #fcfcfc;
  font-size: 1.2rem;
  position: relative;
  transition: all 300ms ease-in-out;
}

@media (max-width: 992px) {
  /* line 86, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
  .c-button--fournisseurs {
    padding: 16px 25px;
    font-size: 1.4rem;
  }
}

/* line 99, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--fournisseurs:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  height: 4px;
  background: #d72b38;
  transition: all 300ms ease-in-out;
  z-index: -1;
  transition-delay: 300ms;
}

/* line 113, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--fournisseurs:hover {
  color: #fcfcfc !important;
}

/* line 116, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--fournisseurs:hover:before {
  height: 100%;
  transition-delay: 0ms;
}

/* line 121, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--fournisseurs:hover i {
  margin-left: 30px;
  color: #171717;
  transition-delay: 300ms;
}

/* line 128, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--fournisseurs i {
  margin-left: 10px;
  color: #d72b38;
  transition: all 300ms ease-in-out;
  transition-delay: 0ms;
  font-size: 1.6rem;
  margin-top: -2px;
}

@media (max-width: 767px) {
  /* line 128, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
  .c-button--fournisseurs i {
    display: none;
  }
}

/* line 142, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--footer {
  color: #fcfcfc;
  transition: all 300ms ease-in-out;
}

/* line 146, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--footer:hover {
  color: #d72b38;
}

/* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--footer:hover i {
  margin-left: 30px;
}

/* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--footer i {
  color: #d72b38;
  margin-left: 10px;
  transition: all 300ms ease-in-out;
  margin-top: -2px;
}

/* line 161, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-button.scss */
.c-button--footer.single-website {
  margin-top: 20px;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services {
  display: flex;
  flex-flow: row wrap;
  position: relative;
  z-index: 9;
}

/* line 7, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__left, .c-services__right {
  width: 50%;
  flex: 1 1 auto;
  min-height: 500px;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__left, .c-services__right {
    width: 100%;
    min-height: 400px;
  }
}

@media (max-width: 550px) {
  /* line 7, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__left, .c-services__right {
    min-height: 300px;
  }
}

/* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__left {
  display: block;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}

/* line 30, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__right {
  background: #d72b38;
  display: flex;
  flex-flow: column wrap;
  align-items: flex-start;
  justify-content: center;
  padding: 40px 60px;
  overflow: hidden;
  position: relative;
  z-index: 0;
}

@media (max-width: 992px) {
  /* line 30, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__right {
    text-align: center;
    align-items: center;
  }
}

@media (max-width: 550px) {
  /* line 30, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__right {
    padding: 40px 20px;
  }
}

/* line 51, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__right:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  transform: rotate(180deg);
  opacity: 0.5;
}

/* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services h2 {
  font-family: "proxima-black";
  font-size: 3rem;
  line-height: 3.5rem;
  color: #fcfcfc;
  text-transform: uppercase;
  margin: 10px auto 0px 0px;
  max-width: 465px;
}

@media (max-width: 992px) {
  /* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services h2 {
    margin: 10px auto;
  }
}

@media (max-width: 550px) {
  /* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services h2 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}

/* line 86, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services h2, .c-services span, .c-services .c-button, .c-services .c-button--lift-up, .c-services
.c-button--rectangle, .c-services
.c-button--fournisseurs, .c-services
.c-button--footer {
  position: relative;
  z-index: 2;
}

/* line 91, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__dispatch {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-around;
  position: relative;
  z-index: 0;
  background: #171717;
}

@media (max-width: 767px) {
  /* line 91, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__dispatch {
    flex-flow: column wrap;
  }
}

/* line 104, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__dispatch a {
  flex: 1 1 auto;
  width: 50%;
  text-align: center;
  padding: 40px 30px;
  position: relative;
  font-family: "proxima-black";
  font-size: 1.8rem;
  color: #fcfcfc;
  text-transform: uppercase;
  display: inline-flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: center;
  z-index: 5;
  cursor: pointer;
}

@media (max-width: 992px) {
  /* line 104, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__dispatch a {
    flex-flow: column nowrap;
    align-items: center;
    justify-content: center;
    flex: 0 0 auto;
    width: 100%;
  }
  /* line 131, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__dispatch a img {
    margin-bottom: 10px;
    margin-right: 0px !important;
  }
}

/* line 139, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__dispatch a:hover:before {
  height: 100%;
}

/* line 143, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__dispatch a:hover img {
  filter: brightness(0);
}

/* line 148, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__dispatch a:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 6px;
  background: #d72b38;
  display: block;
  transition: all 300ms ease-in-out;
  z-index: -1;
}

/* line 162, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__dispatch a img {
  width: 40px;
  height: 40px;
  display: block;
  margin-right: 20px;
  transition: all 300ms ease-in-out;
}

/* line 172, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs {
  width: 100%;
  background-color: #171717;
  background-image: url(../images/fournisseur.png);
  background-repeat: no-repeat;
  background-size: 70%;
  background-position: center;
  position: relative;
}

/* line 181, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  transform: rotate(180deg);
  opacity: 0.7;
}

/* line 196, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs:after {
  content: '';
  position: absolute;
  top: 2px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: auto;
  height: 2px;
  background: #292929;
  width: 100%;
}

@media (max-width: 767px) {
  /* line 196, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__fournisseurs:after {
    display: none;
  }
}

/* line 213, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs ul {
  list-style-type: none;
  padding-left: 0px;
  display: flex;
  flex-flow: row wrap;
  margin: 0 auto;
  position: relative;
  z-index: 10;
}

/* line 222, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs ul li {
  width: 25%;
  padding: 75px 35px 0px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  position: relative;
}

@media (max-width: 992px) {
  /* line 222, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__fournisseurs ul li {
    width: calc(100% / 3);
    padding: 75px 25px 0px;
  }
}

@media (max-width: 767px) {
  /* line 222, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__fournisseurs ul li {
    width: 50%;
    padding: 75px 35px 0px;
  }
}

@media (max-width: 767px) {
  /* line 222, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__fournisseurs ul li {
    width: 100%;
  }
}

/* line 246, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs ul li .fournisseurs-logo {
  height: 100px;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

/* line 253, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs ul li .fournisseurs-logo img {
  max-width: 150px;
}

/* line 258, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__fournisseurs ul li p {
  text-align: center;
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #989898;
  width: 100%;
}

@media (max-width: 767px) {
  /* line 258, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__fournisseurs ul li p {
    font-size: 2.1rem;
    line-height: 2.8rem;
  }
}

/* line 275, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__listing {
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 767px) {
  /* line 275, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__listing {
    flex-flow: column wrap;
  }
}

/* line 283, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__listing > div {
  width: 50%;
}

@media (max-width: 767px) {
  /* line 283, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__listing > div {
    width: 100%;
  }
}

@media (max-width: 767px) {
  /* line 290, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__listing > div.c-services__image:nth-child(2) {
    order: -1;
  }
}

/* line 298, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__listing h2 {
  font-family: "proxima-black";
  font-size: 2.6rem;
  line-height: 3rem;
  color: #fcfcfc;
  margin-top: 0px;
  text-transform: uppercase;
  margin: 0px;
}

/* line 308, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__listing p {
  margin-bottom: 30px;
}

/* line 312, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__listing .c-button, .c-services__listing .c-button--lift-up, .c-services__listing
.c-button--rectangle, .c-services__listing
.c-button--fournisseurs, .c-services__listing
.c-button--footer {
  margin-top: 0px !important;
}

/* line 317, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__item {
  background: #d72b38;
  padding: 80px 60px;
}

@media (max-width: 767px) {
  /* line 317, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__item {
    text-align: center;
    padding: 60px 30px;
  }
}

/* line 328, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__image {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

@media (max-width: 767px) {
  /* line 328, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__image {
    height: 300px;
  }
}

/* line 338, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__autres {
  background: #f1f1f1;
  width: 100% !important;
  padding: 100px 60px 60px;
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 767px) {
  /* line 338, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres {
    flex-flow: column wrap;
    padding: 60px 30px;
  }
}

/* line 351, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__autres > h2 {
  width: 42%;
  margin-right: 8%;
  font-size: 3rem;
  line-height: 3.4rem;
  color: #171717;
}

@media (max-width: 767px) {
  /* line 351, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > h2 {
    width: 100%;
    margin-right: 0px;
    margin-bottom: 60px;
    text-align: center;
  }
}

/* line 366, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__autres > ul {
  padding: 0px;
  list-style-type: none;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 0px;
  width: 50%;
}

@media (max-width: 767px) {
  /* line 366, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > ul {
    width: 100%;
  }
}

/* line 381, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__autres > ul li h3 {
  margin-top: 0px;
  margin-bottom: 0px;
  font-family: "proxima-black";
  text-transform: uppercase;
  color: #171717;
  position: relative;
  z-index: 1;
  font-size: 2.6rem;
  line-height: 3rem;
}

@media (max-width: 767px) {
  /* line 381, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > ul li h3 {
    text-align: center;
    margin-bottom: 15px;
  }
}

@media (max-width: 550px) {
  /* line 381, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > ul li h3 {
    color: #d72b38;
  }
}

/* line 401, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__autres > ul li h3:before {
  content: "";
  display: block;
  width: 60px;
  height: 15px;
  background: #d72b38;
  transform: rotate(-45deg) skewX(45deg);
  position: absolute;
  top: 0px;
  left: -30px;
  z-index: -1;
}

@media (max-width: 767px) {
  /* line 401, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > ul li h3:before {
    left: 0px;
    right: 0px;
    top: 0px;
    bottom: 0px;
    margin: auto;
  }
}

@media (max-width: 550px) {
  /* line 401, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > ul li h3:before {
    display: none;
  }
}

/* line 427, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
.c-services__autres > ul li p {
  color: #989898;
  font-size: 1.4rem;
  line-height: 2.2rem;
  margin-top: 10px;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 427, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-services.scss */
  .c-services__autres > ul li p {
    font-size: 2rem;
    line-height: 2.4rem;
    text-align: center;
  }
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-gradient-separator.scss */
.c-gradient-separator {
  position: absolute;
  bottom: 0px;
  display: block;
  width: 2px;
  height: 90%;
  margin: auto 0;
  background: -moz-linear-gradient(top, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  background: -webkit-linear-gradient(top, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  background: linear-gradient(to bottom, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00292929', endColorstr='#00292929',GradientType=0 );
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-gradient-separator.scss */
  .c-gradient-separator {
    display: none;
  }
}

/* line 18, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-gradient-separator.scss */
li > .c-gradient-separator:first-child,
div > .c-gradient-separator:first-child {
  left: 0px;
}

/* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-gradient-separator.scss */
li > .c-gradient-separator:last-child,
div > .c-gradient-separator:last-child {
  right: 0px;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer {
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  padding-bottom: 20px;
}

/* line 7, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__container {
  display: inline-block;
}

@media (max-width: 992px) {
  /* line 7, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__container {
    text-align: center;
  }
}

/* line 15, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__wrapper {
  background: #171717;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  flex-grow: 1;
  padding-top: 80px;
  position: relative;
}

/* line 24, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__wrapper:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 0;
  transform: rotate(180deg);
  opacity: 0.5;
}

/* line 39, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__wrapper > div {
  width: 25%;
  position: relative;
  padding: 20px 50px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 1199px) {
  /* line 39, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__wrapper > div {
    padding: 20px 30px;
  }
}

@media (max-width: 767px) {
  /* line 39, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__wrapper > div {
    text-align: center;
  }
}

/* line 57, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__wrapper > div:last-child {
  width: 100%;
}

/* line 61, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__wrapper > div h3 {
  font-family: "proxima-black";
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #d72b38;
  text-transform: uppercase;
  margin-bottom: 12px;
  position: relative;
  z-index: 999;
  display: inline-block;
  max-width: 275px;
  margin-top: 0px;
}

@media (max-width: 767px) {
  /* line 61, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__wrapper > div h3 {
    max-width: 100% !important;
  }
}

/* line 81, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer ul {
  list-style-type: none;
  padding-left: 0px;
}

/* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer ul li {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 992px) {
  /* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer ul li {
    align-items: center;
  }
}

/* line 95, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer ul li a {
  font-family: "proxima-black";
  font-size: 1.4rem;
  line-height: 2rem;
  color: #989898;
  text-transform: uppercase;
  margin-bottom: 12px;
  transition: all 300ms ease-in-out;
}

@media (max-width: 767px) {
  /* line 95, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer ul li a {
    font-size: 1.6rem;
  }
}

/* line 108, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer ul li a:hover {
  color: #fcfcfc;
}

/* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__logo img {
  max-width: 240px;
  margin-left: 0px;
  position: relative;
  z-index: 999;
  width: 100%;
}

@media (max-width: 767px) {
  /* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__logo img {
    margin-left: 0px;
  }
}

@media (max-width: 992px) {
  /* line 115, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__logo {
    width: 100% !important;
  }
}

@media (max-width: 992px) {
  /* line 134, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__fournisseurs, .c-footer__nav, .c-footer__contact {
    width: 33.3333% !important;
  }
}

@media (max-width: 767px) {
  /* line 134, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__fournisseurs, .c-footer__nav, .c-footer__contact {
    width: 100% !important;
  }
}

/* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__contact h3 {
  font-size: 2.3rem !important;
  line-height: 3rem !important;
}

@media (max-width: 1199px) {
  /* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__contact h3 {
    font-size: 2rem !important;
    line-height: 2.4rem !important;
  }
}

/* line 159, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__contact p {
  color: #989898;
  max-width: 200px;
}

@media (max-width: 992px) {
  /* line 159, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__contact p {
    max-width: 100%;
  }
}

/* line 170, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__contact .phone-fax span {
  display: block;
  font-family: "proxima-black";
  font-size: 1.5rem;
  line-height: 1.8rem;
  color: #989898;
  margin-bottom: 5px;
}

/* line 179, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__contact .phone-fax span:hover a {
  color: #d72b38;
}

/* line 184, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__contact .phone-fax span a {
  color: white;
  margin-left: 5px;
  transition: all 300ms ease-in-out;
}

/* line 193, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__scroll {
  width: 60px;
  background: #171717;
  border-left: 1px solid #292929;
  position: relative;
  display: flex;
  flex-flow: column wrap;
  justify-content: flex-end;
  align-items: center;
  min-width: 60px;
}

@media (max-width: 767px) {
  /* line 193, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__scroll {
    display: none;
  }
}

/* line 210, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__scroll:hover:before {
  height: 100%;
}

/* line 214, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__scroll:hover i {
  color: #171717;
  margin-bottom: 60px;
}

/* line 220, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__scroll:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 10px;
  background: #d72b38;
  transition: all 500ms ease-in-out;
  z-index: 0;
}

/* line 232, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__scroll i {
  font-size: 2.4rem;
  color: #d72b38;
  margin-bottom: 40px;
  position: relative;
  z-index: 2;
  transition: all 500ms ease-in-out;
}

/* line 242, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright {
  flex: 1;
  border-top: 1px solid #292929;
  text-align: center;
  padding: 25px 30px;
  color: #989898;
  font-size: 1.4rem;
  margin-top: 50px;
  align-items: center !important;
}

@media (max-width: 550px) {
  /* line 242, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__copyright {
    flex-flow: column wrap !important;
  }
  /* line 255, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
  .c-footer__copyright img {
    margin-top: 20px;
    margin-left: 0px;
    display: block;
  }
}

/* line 262, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-footer.scss */
.c-footer__copyright img {
  width: 30px;
  margin-left: 10px;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact {
  display: flex;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact {
    flex-flow: column wrap;
  }
}

/* line 9, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info-wrapper {
  position: relative;
  z-index: 2;
  padding: 40px 30px;
}

/* line 15, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__map {
  width: 66.6666%;
  position: relative;
}

/* line 19, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__map .c-map {
  width: 100%;
  height: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  position: absolute;
}

@media (max-width: 767px) {
  /* line 15, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact__map {
    width: 100%;
    height: 250px;
  }
}

/* line 35, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info {
  width: 33.3333%;
  background: #171717;
  text-align: center;
  position: relative;
}

@media (max-width: 767px) {
  /* line 35, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact__info {
    width: 100%;
  }
}

/* line 46, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  background-position: left center;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  transform: rotate(180deg);
}

/* line 61, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info:after {
  content: '';
}

/* line 66, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info h2 {
  font-family: "proxima-black";
  font-size: 1.8rem;
  line-height: 2.4rem;
  color: #fcfcfc;
  text-transform: uppercase;
  position: relative;
}

@media (max-width: 767px) {
  /* line 66, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact__info h2 {
    font-size: 2.4rem;
    line-height: 2.8rem;
  }
}

/* line 79, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info h2:after {
  content: '';
  display: block;
  width: 90%;
  height: 2px;
  margin: 30px auto;
  background: -moz-linear-gradient(left, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  background: linear-gradient(to right, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00292929', endColorstr='#00292929',GradientType=0 );
}

/* line 92, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info h3 {
  font-family: "proxima-black";
  font-size: 1.4rem;
  line-height: 1.8rem;
  color: #d72b38;
  text-transform: uppercase;
  margin-bottom: 5px !important;
  margin-top: 25px;
}

@media (max-width: 767px) {
  /* line 92, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact__info h3 {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

/* line 107, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info p, .c-contact__info a, .c-contact__info li {
  font-family: "proxima-regular";
  font-size: 1.5rem;
  line-height: 2.2rem;
  color: #fcfcfc;
}

@media (max-width: 767px) {
  /* line 107, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact__info p, .c-contact__info a, .c-contact__info li {
    font-size: 1.8rem;
    line-height: 2.4rem;
  }
}

/* line 119, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info p {
  margin-top: 0px;
}

/* line 123, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__info ul {
  padding-left: 0px;
  list-style-type: none;
  margin-bottom: 25px;
  margin-top: 0px;
}

/* line 132, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__360 {
  position: relative;
}

/* line 135, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__360-badge {
  font-family: "proxima-black";
  font-size: 3.2rem;
  line-height: 3.6rem;
  color: #fcfcfc;
  background: #d72b38;
  padding: 12px 30px;
  display: inline-block;
  position: absolute;
  bottom: 6px;
  left: 20px;
  z-index: 999;
}

/* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__360 iframe {
  width: 100%;
  height: 500px;
  pointer-events: none;
  position: relative;
}

/* line 155, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
.c-contact__360 iframe.clicked {
  pointer-events: auto;
}

@media (max-width: 767px) {
  /* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-contact.scss */
  .c-contact__360 iframe {
    height: 400px;
  }
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/c-deco-lines.scss */
.c-deco-lines {
  position: absolute;
  right: -10px;
  bottom: 30px;
  max-width: 20px;
}

/* line 7, public/wp-content/themes/cpmrs/assets/src/scss/component/c-deco-lines.scss */
.c-deco-lines img {
  width: 100%;
}

/* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__container {
  background-color: #171717;
  background-image: url("../images/contact-form_bg.png");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  padding: 120px 40px;
  text-align: center;
}

@media (max-width: 767px) {
  /* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__container {
    padding: 60px 30px;
  }
}

/* line 18, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__title {
  position: relative;
  z-index: 1;
  margin-bottom: 60px;
}

@media (max-width: 767px) {
  /* line 18, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__title {
    margin-bottom: 20px;
  }
}

/* line 27, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__title:before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  margin: auto;
  width: 120px;
  height: 30px;
  background-color: #d72b38;
  transform: rotate(-45deg) skewX(45deg);
  z-index: -1;
}

@media (max-width: 767px) {
  /* line 27, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__title:before {
    display: none;
  }
}

/* line 47, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form h2 {
  font-family: "proxima-black";
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: #fcfcfc;
  text-transform: uppercase;
  margin-bottom: 5px;
  margin-top: 0px;
}

/* line 57, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form h3 {
  font-family: "proxima-black";
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #fcfcfc;
  text-transform: uppercase;
  margin-bottom: 0px;
  margin-top: 0px;
}

@media (max-width: 767px) {
  /* line 57, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form h3 {
    color: #d72b38;
  }
}

/* line 71, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form .wpcf7 {
  margin-top: 40px;
  max-width: 600px;
  margin: 0 auto;
}

/* line 79, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__wrapper {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
}

/* line 86, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__wrapper span:first-of-type, .c-form__wrapper span:nth-of-type(4) {
  width: 100%;
}

/* line 91, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__wrapper span:nth-of-type(2), .c-form__wrapper span:nth-of-type(3) {
  width: calc(50% - 20px);
}

@media (max-width: 550px) {
  /* line 91, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__wrapper span:nth-of-type(2), .c-form__wrapper span:nth-of-type(3) {
    width: 100%;
    padding: 0px !important;
  }
}

/* line 101, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__wrapper span:nth-of-type(2) {
  padding-right: 20px;
}

/* line 105, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__wrapper span:nth-of-type(3) {
  padding-left: 20px;
}

/* line 110, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__wrapper br {
  display: none;
}

/* line 115, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__captcha {
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  width: 100%;
  margin-top: 30px;
}

/* line 124, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__textbox,
.c-form__textarea {
  background: transparent;
  border-top: 0px;
  border-left: 0px;
  border-right: 0px;
  border-bottom: 2px solid #989898;
  font-family: "proxima-regular";
  font-size: 1.5rem;
  color: #fcfcfc;
  width: 100%;
  margin-top: 30px;
  padding: 15px 20px;
}

@media (max-width: 550px) {
  /* line 124, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__textbox,
  .c-form__textarea {
    font-size: 1.8rem;
    text-align: center;
  }
}

/* line 145, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__textarea {
  height: 150px;
}

/* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__submit {
  position: relative;
  display: inline-block;
  margin: 40px auto 0px;
}

/* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__submit input {
  border: 0px;
  background: transparent;
  font-family: "proxima-black";
  font-size: 1.5rem;
  text-transform: uppercase;
  color: #fcfcfc;
  padding: 20px 70px 20px 30px;
  position: relative;
  z-index: 2;
  cursor: pointer;
}

@media (max-width: 550px) {
  /* line 154, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__submit input {
    padding: 20px 30px;
  }
}

/* line 172, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__submit:before {
  content: '';
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  height: 3px;
  background-color: #d72b38;
  margin: 0 auto;
  transition: all 300ms ease-in-out;
  z-index: 0;
}

/* line 186, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__submit:after {
  content: "\f178";
  display: inline-block;
  font: normal normal normal 14px/1 FontAwesome;
  font-size: inherit;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  position: absolute;
  right: 30px;
  top: 22px;
  margin: auto 0;
  color: #d72b38;
  transition: color 300ms ease-in-out;
}

@media (max-width: 550px) {
  /* line 186, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
  .c-form__submit:after {
    display: none;
  }
}

/* line 207, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__submit:hover:before {
  height: 100%;
}

/* line 211, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__submit:hover:after {
  color: #171717;
}

/* line 222, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.c-form__textbox:focus,
.c-form__textarea:focus,
.c-form__submit input:focus {
  outline: 0;
}

/* line 227, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.screen-reader-response {
  display: none;
}

/* line 232, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.wpcf7-form-control-wrap {
  position: relative;
  text-align: left;
}

/* line 237, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.wpcf7-not-valid-tip {
  color: #d72b38;
  font-size: 1.2rem;
  padding: 0px 25px;
  margin-top: 10px;
  display: block;
}

/* line 246, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.wpcf7-mail-sent-ok,
.wpcf7-validation-errors {
  font-family: "proxima-regular";
  font-size: 1.4rem;
  margin-top: 30px;
}

/* line 253, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.wpcf7-mail-sent-ok {
  color: #7ae651;
}

/* line 257, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-form.scss */
.wpcf7-validation-errors {
  color: #d72b38;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header {
  position: relative;
}

/* line 4, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header:before {
  content: "";
  position: absolute;
  top: 0;
  left: 20px;
  right: 20px;
  bottom: -30px;
  margin: auto;
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  background-repeat: no-repeat;
  opacity: 0.1;
}

/* line 18, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header__container {
  max-width: 840px;
  padding: 80px 20px 60px;
  margin: 0 auto;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 9;
}

@media (max-width: 767px) {
  /* line 18, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
  .c-page-header__container {
    flex-flow: column wrap;
    padding: 40px 20px;
    justify-content: center;
    text-align: center;
  }
}

/* line 37, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header__container > div {
  width: 50%;
}

@media (max-width: 767px) {
  /* line 37, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
  .c-page-header__container > div {
    width: 100%;
  }
}

/* line 48, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header h1 {
  font-family: "proxima-black";
  font-size: 4.5rem;
  line-height: 5.2rem;
  color: #171717;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

@media (max-width: 767px) {
  /* line 48, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
  .c-page-header h1 {
    margin-bottom: 0px;
  }
}

/* line 61, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header h1:before {
  content: '';
  position: absolute;
  left: -50px;
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  width: 175px;
  height: 30px;
  background: #d72b38;
  transform: rotate(-45deg) skewX(45deg);
  z-index: -1;
}

@media (max-width: 767px) {
  /* line 61, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
  .c-page-header h1:before {
    display: none;
  }
}

/* line 82, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
.c-page-header__punchline p {
  font-family: "proxima-black";
  font-size: 2.4rem;
  line-height: 2.8rem;
  color: #d72b38;
  text-transform: uppercase;
  max-width: 300px;
  margin-left: auto;
}

@media (max-width: 767px) {
  /* line 82, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-page-header.scss */
  .c-page-header__punchline p {
    text-align: center;
    max-width: 100%;
  }
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  padding-top: 0px;
  margin-bottom: -30px;
}

@media (max-width: 767px) {
  /* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
  .c-bottom-cta {
    flex-flow: column wrap;
  }
}

/* line 12, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta > a {
  width: 50%;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  border-bottom: 7px solid #d72b38;
  cursor: pointer;
}

@media (max-width: 767px) {
  /* line 12, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
  .c-bottom-cta > a {
    width: 100%;
  }
}

/* line 26, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item {
  min-height: 375px;
  background-size: cover;
  background-position: center;
  position: relative;
  padding: 40px;
  text-align: center;
}

@media (max-width: 767px) {
  /* line 26, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
  .c-bottom-cta__item {
    min-height: 300px;
  }
}

/* line 41, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item:hover:before {
  background: rgba(0, 0, 0, 0.6);
}

/* line 45, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item:hover i {
  opacity: 1;
  margin-left: 0px;
}

/* line 51, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item:before {
  content: '';
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.4);
  transition: all 300ms ease-in-out;
  z-index: 0;
}

/* line 63, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item > div {
  position: relative;
  z-index: 9999;
}

/* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item > div span {
  font-family: "proxima-black";
  font-size: 2.8rem;
  line-height: 3.2rem;
  color: #fcfcfc;
  text-transform: uppercase;
  position: relative;
  z-index: 999;
  max-width: 320px;
  margin: 0 auto;
  display: block;
  margin-bottom: 10px;
}

@media (max-width: 767px) {
  /* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
  .c-bottom-cta__item > div span {
    max-width: 250px;
  }
}

/* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
.c-bottom-cta__item > div i {
  opacity: 0;
  color: #d72b38;
  transition: all 300ms ease-in-out;
  font-size: 2rem;
  margin-left: -20px;
}

@media (max-width: 767px) {
  /* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-bottom-cta.scss */
  .c-bottom-cta__item > div i {
    display: none;
  }
}

/* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team-photo {
  height: 600px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

@media (max-width: 1199px) {
  /* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team-photo {
    height: 400px;
  }
}

@media (max-width: 992px) {
  /* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team-photo {
    height: 250px;
  }
}

@media (max-width: 767px) {
  /* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team-photo {
    height: 200px;
  }
}

/* line 22, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team-list {
  background: #171717;
  padding: 60px;
  position: relative;
}

@media (max-width: 550px) {
  /* line 22, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team-list {
    padding: 60px 30px;
  }
}

/* line 32, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team-list:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  transform: rotate(180deg);
  opacity: 0.5;
}

/* line 48, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team-order,
.c-about__team h2,
.c-about__team ul {
  position: relative;
  z-index: 2;
}

/* line 55, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team-order {
  font-family: "proxima-black";
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: #989898;
  text-transform: uppercase;
}

@media (max-width: 767px) {
  /* line 55, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team-order {
    display: none;
  }
}

/* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team h2 {
  font-family: "proxima-black";
  font-size: 3.2rem;
  line-height: 3.2rem;
  color: #fcfcfc;
  text-transform: uppercase;
  text-align: center;
}

/* line 76, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team ul {
  list-style-type: none;
  padding: 0px;
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: 60px;
}

/* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team ul li {
  width: 25%;
  text-align: center;
  margin-bottom: 40px;
}

@media (max-width: 767px) {
  /* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team ul li {
    width: 50%;
  }
}

@media (max-width: 550px) {
  /* line 85, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__team ul li {
    width: 100%;
  }
}

/* line 98, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team ul li h3 {
  text-transform: uppercase;
  font-family: "proxima-black";
  color: #d72b38;
  font-size: 1.8rem;
  line-height: 1.8rem;
  margin-bottom: 8px;
}

/* line 107, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__team ul li p {
  margin: 0px;
  text-transform: uppercase;
  font-size: 1.4rem;
  line-height: 1.4rem;
}

/* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid {
  height: 600px;
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 1199px) {
  /* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__grid {
    height: 450px;
  }
}

@media (max-width: 992px) {
  /* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__grid {
    height: 350px;
  }
}

@media (max-width: 767px) {
  /* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__grid {
    height: 250px;
  }
}

@media (max-width: 550px) {
  /* line 117, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__grid {
    display: none;
  }
}

/* line 136, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid > div {
  height: 100%;
}

/* line 140, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid-one-third {
  width: 30%;
}

/* line 144, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid-two-third {
  width: 70%;
}

/* line 149, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid-large, .c-about__grid-small {
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top center;
}

/* line 156, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid-large {
  width: 100%;
  height: 100%;
}

/* line 161, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__grid-small {
  width: 100%;
  height: 50%;
}

/* line 167, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique {
  padding-top: 0px;
}

/* line 170, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-container {
  background: #f1f1f1;
  padding: 100px 60px;
}

@media (max-width: 767px) {
  /* line 170, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-container {
    padding: 60px 20px;
  }
}

/* line 179, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-item {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-between;
  margin-bottom: 40px;
}

/* line 185, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-item > div {
  width: 50%;
}

@media (max-width: 992px) {
  /* line 185, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-item > div {
    width: 100%;
  }
}

/* line 194, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-boxed {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
}

/* line 201, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-content {
  max-width: 400px;
}

@media (max-width: 992px) {
  /* line 201, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-content {
    max-width: 100%;
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: space-between;
    padding: 50px 0px;
  }
}

@media (max-width: 992px) {
  /* line 213, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-content > * {
    width: 50%;
  }
}

@media (max-width: 767px) {
  /* line 213, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-content > * {
    width: 100%;
    text-align: center;
  }
}

/* line 226, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-year {
  font-family: "proxima-black";
  font-size: 15rem;
  line-height: 15rem;
  color: #d72b38;
}

@media (max-width: 550px) {
  /* line 226, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-year {
    font-size: 11rem;
    line-height: 12rem;
  }
}

/* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique-image {
  background-size: cover;
  background-repeat: no-repeat;
  min-height: 320px;
  background-position: center;
}

@media (max-width: 992px) {
  /* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-image {
    order: -1;
    min-height: 400px;
  }
}

@media (max-width: 767px) {
  /* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-image {
    min-height: 300px;
  }
}

@media (max-width: 550px) {
  /* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique-image {
    min-height: 200px;
  }
}

/* line 258, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique p {
  color: #989898;
  font-size: 1.8rem;
  line-height: 2.4rem;
}

/* line 264, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
.c-about__historique h2 {
  text-align: center;
  font-family: "proxima-black";
  font-size: 3.2rem;
  line-height: 3.2rem;
  text-transform: uppercase;
  max-width: 550px;
  margin: 0 auto 100px;
}

@media (max-width: 767px) {
  /* line 264, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-about.scss */
  .c-about__historique h2 {
    margin-bottom: 50px;
  }
}

/* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__intro {
  display: flex;
  flex-flow: row wrap;
}

@media (max-width: 767px) {
  /* line 3, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__intro {
    flex-flow: column wrap;
  }
}

/* line 11, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__intro > div:first-of-type {
  width: 65%;
}

@media (max-width: 767px) {
  /* line 11, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__intro > div:first-of-type {
    width: 100%;
    min-height: 300px;
  }
}

/* line 20, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__intro > div:last-of-type {
  width: 35%;
}

@media (max-width: 767px) {
  /* line 20, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__intro > div:last-of-type {
    width: 100%;
  }
}

/* line 31, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__attribute svg {
  max-width: 45px;
  fill: #d72b38 !important;
}

/* line 35, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__attribute svg path, .c-product-single__attribute svg g {
  fill: #d72b38 !important;
}

/* line 41, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__attribute h3 {
  font-size: 1.6rem;
  font-family: "proxima-regular";
  color: #989898;
  font-weight: 400;
  margin-bottom: 40px;
}

/* line 50, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__image {
  background-size: cover;
  background-position: center;
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  min-height: 500px;
  padding: 60px;
}

@media (max-width: 767px) {
  /* line 50, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__image {
    min-height: 250px;
  }
}

/* line 67, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__image:before {
  content: '';
  background: rgba(0, 0, 0, 0.7);
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

/* line 77, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__image span, .c-product-single__image h1 {
  position: relative;
  z-index: 2;
}

/* line 82, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__image span {
  font-family: "proxima-black";
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #d72b38;
  text-transform: uppercase;
}

@media (max-width: 550px) {
  /* line 82, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__image span {
    font-size: 1.8rem;
    line-height: 2.2rem;
  }
}

/* line 95, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__image h1 {
  font-family: "proxima-black";
  font-size: 6rem;
  line-height: 6rem;
  margin-top: 0px;
  color: #fcfcfc;
  text-transform: uppercase;
  margin-bottom: 0px;
}

@media (max-width: 550px) {
  /* line 95, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__image h1 {
    font-size: 5rem;
    line-height: 5.5rem;
  }
}

/* line 111, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__next {
  right: 0px;
}

/* line 115, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__previous {
  left: 0px;
}

/* line 119, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__next, .c-product-single__previous {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0px;
  bottom: 0px;
  margin: auto 0;
  width: 50px;
  height: 50px;
  background: transparent;
  cursor: pointer;
}

@media (max-width: 767px) {
  /* line 119, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__next, .c-product-single__previous {
    bottom: initial;
  }
}

/* line 138, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__next:before, .c-product-single__previous:before {
  content: '';
  position: absolute;
  bottom: 0px;
  left: 0px;
  right: 0px;
  height: 3px;
  background: #d72b38;
  transition: all 300ms ease-in-out;
  z-index: 2;
}

/* line 152, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__next:hover:before, .c-product-single__previous:hover:before {
  height: 100%;
}

/* line 157, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__next i, .c-product-single__previous i {
  color: #fcfcfc;
  position: relative;
  z-index: 3;
}

/* line 164, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__description {
  background: #171717;
  padding: 60px 40px 0px;
  text-align: center;
}

/* line 170, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__description > img {
  max-width: 120px;
}

/* line 174, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__description > p {
  font-size: 1.2rem;
  line-height: 1.8rem;
  max-width: 250px;
  margin: 40px auto;
  position: relative;
}

@media (max-width: 992px) {
  /* line 174, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__description > p {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

/* line 186, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__description > p:before {
  content: '';
  display: block;
  width: 90%;
  height: 2px;
  margin: 30px auto;
  background: -moz-linear-gradient(left, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  background: -webkit-linear-gradient(left, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  background: linear-gradient(to right, rgba(152, 152, 152, 0) 0%, rgba(152, 152, 152, 0) 1%, rgba(152, 152, 152, 0.3) 50%, rgba(152, 152, 152, 0) 100%);
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00292929', endColorstr='#00292929',GradientType=0 );
}

/* line 200, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__container {
  background: #171717;
  position: relative;
  padding: 60px;
  border-bottom: 7px solid #d72b38;
}

@media (max-width: 550px) {
  /* line 200, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__container {
    padding: 60px 20px;
  }
}

/* line 210, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__container:before {
  content: '';
  background-image: url(../images/dot-pattern.png);
  background-size: cover;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  pointer-events: none;
  z-index: 1;
  transform: rotate(180deg);
  opacity: 0.75;
}

/* line 225, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__container h2 {
  font-family: "proxima-black";
  font-size: 3.2rem;
  line-height: 3.8rem;
  color: #fcfcfc;
  text-transform: uppercase;
  position: relative;
  z-index: 9999;
  text-align: center;
  max-width: 300px;
  margin: 0 auto;
}

/* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__container ul {
  list-style-type: none;
  padding: 0px;
  position: relative;
  z-index: 9999;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
  margin-top: 70px;
  margin-bottom: -170px;
}

@media (max-width: 992px) {
  /* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__container ul {
    margin-bottom: 0px;
  }
}

@media (max-width: 767px) {
  /* line 238, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__container ul {
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
  }
}

/* line 260, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__container ul li {
  width: 225px;
  height: 225px;
  background: #fff;
  border-radius: 100%;
  margin: 0 20px;
  box-shadow: 0px 0px 50px rgba(0, 0, 0, 0.5);
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: bottom 20px center;
}

@media (max-width: 992px) {
  /* line 260, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__container ul li {
    margin: 0px 15px 40px;
  }
}

@media (max-width: 550px) {
  /* line 260, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__container ul li {
    width: 200px;
    height: 200px;
    margin: 0px 0px 40px;
  }
}

/* line 281, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__container ul li h3 {
  font-family: "proxima-black";
  font-size: 1.2rem;
  line-height: 1.6rem;
  color: #171717;
  text-transform: uppercase;
  text-align: center;
  max-width: 150px;
  margin: 50px auto 0;
}

@media (max-width: 992px) {
  /* line 281, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__container ul li h3 {
    font-size: 1.6rem;
    line-height: 2rem;
  }
}

/* line 300, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__services {
  padding-top: 0px;
}

/* line 303, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__services-container {
  background: #f1f1f1;
  padding: 220px 60px 100px;
  display: flex;
  flex-flow: row wrap;
  justify-content: center;
  align-items: flex-start;
}

@media (max-width: 992px) {
  /* line 303, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__services-container {
    padding: 100px 60px;
    justify-content: space-between;
  }
}

@media (max-width: 767px) {
  /* line 303, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__services-container {
    padding: 60px 30px;
    flex-flow: column wrap;
    justify-content: center;
    align-items: center;
    text-align: center;
  }
}

/* line 324, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__services-container > div {
  max-width: 300px;
  width: 100%;
  margin: 0px 40px;
}

@media (max-width: 992px) {
  /* line 324, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__services-container > div {
    width: 46%;
    margin: 0px;
    max-width: 100%;
  }
}

@media (max-width: 767px) {
  /* line 324, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__services-container > div {
    margin: 30px 0px;
    width: 100%;
  }
}

/* line 343, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__services h2 {
  margin-top: 0px;
  font-family: "proxima-black";
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: #171717;
  text-transform: uppercase;
  position: relative;
  z-index: 1;
}

/* line 353, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__services h2:before {
  content: "";
  display: block;
  width: 60px;
  height: 15px;
  background: #d72b38;
  -webkit-transform: rotate(-45deg) skewX(45deg);
  -ms-transform: rotate(-45deg) skewX(45deg);
  transform: rotate(-45deg) skewX(45deg);
  position: absolute;
  top: 0;
  left: -30px;
  z-index: -1;
}

@media (max-width: 767px) {
  /* line 353, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
  .c-product-single__services h2:before {
    display: none;
  }
}

/* line 373, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-product-single.scss */
.c-product-single__services p {
  color: #989898;
}

/* line 6, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-frame-border.scss */
.c-frame-border, .c-frame-border--top,
.c-frame-border--bottom {
  position: fixed;
  left: 0px;
  right: 0px;
  height: 20px;
  width: 100%;
  background: white;
  z-index: 9999;
}

/* line 16, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-frame-border.scss */
.c-frame-border--top {
  top: 0px;
}

/* line 20, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-frame-border.scss */
.c-frame-border--bottom {
  bottom: 0px;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.c-mobile__nav {
  position: fixed;
  top: 0px;
  left: 0px;
  transform: translate(-100%, 0px);
  background: #000;
  width: 100%;
  max-width: 400px;
  height: 100%;
  transition: all 300ms ease-out;
  z-index: 9999999;
  padding: 30px;
  box-sizing: border-box;
  display: flex;
  flex-flow: column nowrap;
  justify-content: space-between;
}

/* line 18, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.c-mobile__nav.active {
  transform: translate(0%, 0px);
}

/* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-menu-button {
  display: none;
  margin-bottom: 0px;
  position: relative;
  z-index: 9999;
}

/* line 29, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-menu-button i {
  color: white;
  transition: all 500ms ease-out;
}

/* line 34, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-menu-button:hover i {
  color: white;
}

@media (max-width: 992px) {
  /* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
  .js-menu-button {
    display: block;
  }
}

@media (max-width: 550px) {
  /* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
  .js-menu-button {
    margin-bottom: 20px;
    position: absolute;
    bottom: 0px;
    right: 0px;
    background: #d72b38;
    margin-bottom: 0px;
    padding: 15px 20px;
  }
}

/* line 53, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav {
  overflow: hidden;
}

/* line 56, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav:before {
  display: block;
  height: 100%;
  width: 100%;
  position: absolute;
  transform: rotate(45deg);
  opacity: 0.15;
  pointer-events: none;
  width: 150%;
  height: 150%;
  top: -100px;
}

/* line 71, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav .c-mobile__logo img {
  max-width: 160px;
  height: auto;
  width: 100%;
}

/* line 80, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav .c-mobile__menu ul {
  display: flex;
  flex-flow: column wrap;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  list-style-type: none;
  padding-left: 0px;
}

/* line 91, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav .c-mobile__menu ul li a {
  font-size: 20px;
  line-height: 26px;
  margin-bottom: 20px;
  display: block;
  font-family: "proxima-black";
  color: white;
  transition: all 300ms ease-in-out;
  text-transform: uppercase;
}

/* line 101, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav .c-mobile__menu ul li a:hover {
  color: #d72b38;
}

/* line 107, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-mobile-nav .c-mobile__menu ul li.current-menu-item a:before {
  width: 20px;
}

/* line 115, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-menu-button.close {
  position: absolute;
  top: 30px !important;
  right: 30px !important;
  bottom: initial !important;
  background: transparent;
  color: #fff;
  border: 0px;
  font-size: 14px;
}

/* line 125, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.js-menu-button.close:focus {
  outline: 0;
}

/* line 130, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.c-mobile__phone {
  font-family: "proxima-black";
  letter-spacing: 1px;
  font-weight: bold;
  color: white;
  text-transform: uppercase;
  font-size: 13px;
  -webkit-font-smoothing: antialiased;
}

/* line 139, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-mobile-nav.scss */
.c-mobile__phone a {
  color: #d72b38;
  margin-right: 5px;
}

/* line 1, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-error.scss */
.c-error {
  padding: 125px 40px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  margin: 20px 20px 0px;
  background: #f1f1f1;
}

/* line 10, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-error.scss */
.c-error__number {
  font-family: "proxima-black";
  font-size: 140px;
  line-height: 120px;
  color: #d72b38;
}

/* line 17, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-error.scss */
.c-error h1 {
  font-family: "proxima-black";
  text-transform: uppercase;
  margin-bottom: 5px;
}

/* line 23, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-error.scss */
.c-error__why {
  font-family: "proxima-regular";
  font-size: 1.6rem;
  text-align: center;
  color: #9d9d9d;
  margin: 8px 0px;
}

/* line 30, public/wp-content/themes/cpmrs/assets/src/scss/component/_c-error.scss */
.c-error__why span {
  font-size: 1.8rem;
  display: block;
  color: #333;
}
