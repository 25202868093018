.c-product-single {

	&__intro {
		display:flex;
		flex-flow:row wrap;

		@include mq('small') {
			flex-flow:column wrap;
		}

		& > div:first-of-type {
			width:65%;

			@include mq('small') {
				width:100%;
				min-height:300px;
			}
		}

		& > div:last-of-type {
			width:35%;

			@include mq('small') {
				width:100%;
			}
		}
	}

	&__attribute {

		svg {
			max-width:45px;
			fill: $red !important;

			path, g {
				fill: $red !important;
			}
		}


		h3 {
			font-size:1.6rem;
			font-family: $p-regular;
			color:$grey;
			font-weight:400;
			margin-bottom:40px;
		}
	}

	&__image {
		background-size:cover;
		background-position:center;
		display: flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:center;
		position:relative;
		z-index: 0;
		min-height: 500px;
		padding:60px;

		@include mq('small') {
			min-height: 250px;
		}
		

		&:before {
			content:'';
			background:rgba(0,0,0,0.7);
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
		}

		span,h1 {
			position:relative;
			z-index: 2;
		}

		span {
			font-family: $p-black;
			font-size:1.4rem;
			line-height: 1.6rem;
			color:$red;
			text-transform:uppercase;

			@include mq('xsmall') {
				font-size:1.8rem;
				line-height: 2.2rem;
			}
		}

		h1 {
			font-family: $p-black;
			font-size:6rem;
			line-height: 6rem;
			margin-top:0px;
			color:$white;
			text-transform:uppercase;
			margin-bottom:0px;

			@include mq('xsmall') {
				font-size:5rem;
				line-height: 5.5rem;
			}
		}
	}

	&__next {
		right:0px;
	}

	&__previous {
		left:0px;
	}

	&__next,
	&__previous {
		display: flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:center;
		position:absolute;
		top:0px;
		bottom:0px;
		margin:auto 0;
		width:50px;
		height:50px;
		background:transparent;
		cursor:pointer;

		@include mq('small') {
			bottom:initial;
		}

		&:before {
			content:'';
			position:absolute;
			bottom:0px;
			left:0px;
			right:0px;
			height:3px;
			background:$red;
			transition:all 300ms ease-in-out;
			z-index: 2;
		}

		&:hover {

			&:before {
				height:100%;
			}
		}

		i {
			color:$white;
			position:relative;
			z-index: 3
		}
	}

	&__description {
		background:$black;
		padding:60px 40px 0px;
		
		text-align: center;

		& > img {
			max-width:120px;
		}

		& > p {
			font-size:1.2rem;
			line-height: 1.8rem;
			max-width:250px;
			margin:40px auto;
			position:relative;

			@include mq('medium') {
				font-size:1.6rem;
				line-height: 2rem;
			}

			&:before {
				content:'';
				display:block;
				width:90%;
				height:2px;
				margin:30px auto;
				background: -moz-linear-gradient(left, rgba($grey,0) 0%, rgba($grey,0) 1%, rgba($grey,0.3) 50%, rgba($grey,0) 100%);
				background: -webkit-linear-gradient(left, rgba($grey,0) 0%,rgba($grey,0) 1%,rgba($grey,0.3) 50%,rgba($grey,0) 100%);
				background: linear-gradient(to right, rgba($grey,0) 0%,rgba($grey,0) 1%,rgba($grey,0.3) 50%,rgba($grey,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00292929', endColorstr='#00292929',GradientType=0 );
			}
		}
	}

	&__container {
		background:$black;
		position:relative;
		padding:60px;
		border-bottom:7px solid $red;

		@include mq('xsmall') {
			padding:60px 20px;
		}

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:cover;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 1;
			transform:rotate(180deg);
			opacity: 0.75;
		}

		h2 {
			font-family:$p-black;
			font-size:3.2rem;
			line-height:3.8rem;
			color:$white;
			text-transform:uppercase;
			position:relative;
			z-index: 9999;
			text-align: center;
		    max-width: 300px;
   			margin: 0 auto;
		}

		ul {
			list-style-type: none;
			padding:0px;
			position:relative;
			z-index: 9999;
			display: flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:flex-start;
			margin-top:70px;
			margin-bottom:-170px;

			@include mq('medium') {
				margin-bottom:0px;
			}

			@include mq('small') {
				flex-flow:column wrap;
				justify-content: center;
				align-items:center;
			}

			li {
				width:225px;
				height:225px;
				background:#fff;
				border-radius:100%;
				margin:0 20px;
				box-shadow:0px 0px 50px rgba(0,0,0,0.5);
				background-size: 50%;
			    background-repeat: no-repeat;
			    background-position: bottom 20px center;

				@include mq('medium') {
					margin:0px 15px 40px;
				}

				@include mq('xsmall') {
					width:200px;
					height:200px;
					margin:0px 0px 40px;
				}

				h3 {
					font-family:$p-black;
					font-size:1.2rem;
					line-height: 1.6rem;
					color:$black;
					text-transform:uppercase;
					text-align:center;
					max-width:150px;
					margin:50px auto 0;

					@include mq('medium') {
						font-size: 1.6rem;
						line-height: 2rem;
					}
				}
			}
		}
	}

	&__services {
		padding-top:0px;

		&-container {
			background:$grey-light;
			padding:220px 60px 100px;
			display: flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:flex-start;

			@include mq('medium') {
				padding:100px 60px;
				justify-content: space-between;
			}

			@include mq('small') {
				padding:60px 30px;
				flex-flow:column wrap;
				justify-content: center;
				align-items:center;
				text-align: center;
			}

			& > div {
				max-width:300px;
				width:100%;
				
				margin:0px 40px;

				@include mq('medium') {
					width:46%;
					margin:0px;
					max-width: 100%;
				}

				@include mq('small') {
					margin:30px 0px;
					width:100%;
				}
			}
		}

		h2 {
			margin-top:0px;
			font-family: $p-black;
			font-size:2.2rem;
			line-height: 2.8rem;
			color:$black;
			text-transform:uppercase;
			position:relative;
			z-index: 1;

			&:before {
				content: "";
				display: block;
				width: 60px;
				height: 15px;
				background: #d72b38;
				-webkit-transform: rotate(-45deg) skewX(45deg);
				-ms-transform: rotate(-45deg) skewX(45deg);
				transform: rotate(-45deg) skewX(45deg);
				position: absolute;
				top: 0;
				left: -30px;
				z-index: -1;

				@include mq('small') {
					display: none;
				}
			}
		}

		p {
			color:$grey;
		}
	}
}