.c-bottom-cta {
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	padding-top:0px;
	margin-bottom:-30px;

	@include mq('small') {
		flex-flow:column wrap;
	}

	& > a {
		width:50%;
		display:flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items: center;
		border-bottom:7px solid $red;
		cursor:pointer;

		@include mq('small') {
			width:100%;
		}
	}

	&__item {
		min-height:375px;
		background-size:cover;
		background-position:center;
		position:relative;
		padding:40px;
		
		text-align:center;

		@include mq('small') {
			min-height: 300px;
		}

		&:hover {

			&:before {
				background:rgba(0,0,0,0.6);
			}

			i {
				opacity:1;
				margin-left:0px;
			}
		}

		&:before {
			content:'';
			position:absolute;
			top:0px;
			right:0px;
			bottom:0px;
			left:0px;
			background:rgba(0,0,0,0.4);
			transition:all 300ms ease-in-out;
			z-index: 0;
		}

		& > div {
			position:relative;
			z-index: 9999;

			span {
				font-family: $p-black;
				font-size:2.8rem;
				line-height: 3.2rem;
				color:$white;
				text-transform:uppercase;
				position:relative;
				z-index: 999;
				max-width:320px;
				margin:0 auto;
				display: block;
				margin-bottom:10px;

				@include mq('small') {
					max-width: 250px;
				}
			}

			i {
				opacity:0;
				color:$red;
				transition:all 300ms ease-in-out;
				font-size:2rem;
				margin-left:-20px;

				@include mq('small') {
					display: none;
				}
			}
		}
	}
}