.c-footer {
	display:flex;
	flex-flow:row nowrap;
	justify-content:space-between;
	padding-bottom:20px;

	&__container {
		display:inline-block;

		@include mq('medium') {
			text-align: center;
		}
	}

	&__wrapper {
		background:$black;
		display: flex;
		flex-flow:row wrap;
		justify-content: space-between;
		flex-grow:1;
		padding-top:80px;
		position:relative;

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:cover;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 0;
			transform:rotate(180deg);
			opacity: 0.5;
		}

		& > div {
			width:25%;
			position:relative;
			padding:20px 50px;
			
			display: flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:flex-start;

			@include mq('large') {
				padding:20px 30px;
			}

			@include mq('small') {
				text-align: center;
			}

			&:last-child {
				width:100%;
			}

			h3 {
				font-family: $p-black;
				font-size:1.8rem;
				line-height: 2.4rem;
				color:$red;
				text-transform:uppercase;
				margin-bottom:12px;
				position:relative;
				z-index: 999;
				display:inline-block;
				max-width:275px;
				margin-top:0px;

				@include mq('small') {
					max-width:100% !important;
				}
			}
		}
	}

	ul {
		list-style-type:none;
		padding-left:0px;

		li {
			display: flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items:flex-start;

			@include mq('medium') {
				align-items:center;
			}

			a {
				font-family: $p-black;
				font-size:1.4rem;
				line-height: 2rem;
				color:$grey;
				text-transform:uppercase;
				margin-bottom:12px;
				transition: all 300ms ease-in-out;

				@include mq('small') {
					font-size:1.6rem;
				}

				&:hover {
					color:$white;
				}
			}
		}
	}

	&__logo {

		img {
			max-width:240px;
			margin-left:0px;
			position:relative;
			z-index: 999;
			width:100%;

			@include mq('small') {
				margin-left:0px;
			}
		}

		@include mq('medium') {
			width:100% !important;
		}
	}

	&__fournisseurs,
	&__nav,
	&__contact {

		@include mq('medium') {
			width:33.3333% !important;
		}

		@include mq('small') {
			width:100% !important;
		}
	}

	&__contact {

		h3 {
			font-size:2.3rem !important;
			line-height: 3rem !important;

			@include mq('large') {
				font-size:2rem !important;
				line-height: 2.4rem !important;
			}
		}

		p {
			color:$grey;
			max-width:200px;

			@include mq('medium') {
				max-width:100%;
			}
		}

		.phone-fax {

			span {
				display: block;
				font-family: $p-black;
				font-size:1.5rem;
				line-height: 1.8rem;
				color:$grey;
				margin-bottom:5px;

				&:hover {
					a {
						color:$red;
					}
				}

				a {
					color:white;
					margin-left:5px;
					transition:all 300ms ease-in-out;
				}
			}
		}
	}

	&__scroll {
		width:60px;
		background:$black;
		border-left:1px solid $grey-dark;
		position: relative;
		display: flex;
		flex-flow:column wrap;
		justify-content: flex-end;;
		align-items: center;
		min-width: 60px;

		@include mq('small') {
			display:none;
		}

		&:hover {

			&:before {
				height:100%;
			}

			i {
				color:$black;
				margin-bottom:60px;
			}
		}

		&:before {
			content:'';
			position:absolute;
			bottom:0px;
			left:0px;
			right:0px;
			height:10px;
			background:$red;
			transition:all 500ms ease-in-out;
			z-index: 0;
		}

		i {
			font-size:2.4rem;
			color:$red;
			margin-bottom:40px;
			position:relative;
			z-index: 2;
			transition:all 500ms ease-in-out;
		}
	}

	&__copyright {
		flex:1;
		border-top:1px solid $grey-dark;
		text-align:center;
		padding:25px 30px;
		color:$grey;
		font-size:1.4rem;
		margin-top:50px;
		align-items:center !important;

		@include mq('xsmall') {
			flex-flow:column wrap !important;

			img {
				margin-top:20px;
				margin-left:0px;
				display: block;
			}
		}

		img {
			width:30px;
			margin-left:10px;
		}
	}
}