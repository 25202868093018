/* =======================================================================
Responsive
========================================================================== */
$breakpoints: (
	'xsmall': 		(max-width: 550px),
	'small': 		(max-width: 767px),
	'medium': 		(max-width: 992px),
	'large': 		(max-width: 1199px),
	'xxl': 			(max-width: 1439px),
);


/* =======================================================================
Font-face
========================================================================== */

@font-face {
	font-family: 'proxima-black';
	src: url('../fonts/proxima-nova/proxima-nova_black.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/proxima-nova/proxima-nova_black.otf') format("opentype"),
		 url('../fonts/proxima-nova/proxima-nova_black.ttf') format("truetype");
}

@font-face {
	font-family: 'proxima-bold';
	src: url('../fonts/proxima-nova/proxima-nova_bold.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/proxima-nova/proxima-nova_bold.otf') format("opentype"),
	 	 url('../fonts/proxima-nova/proxima-nova_bold.ttf') format("truetype");
}

@font-face {
	font-family: 'proxima-regular';
	src: url('../fonts/proxima-nova/proxima-nova_regular.eot?#iefix') format('embedded-opentype'),
		 url('../fonts/proxima-nova/proxima-nova_regular.otf') format("opentype"),
		 url('../fonts/proxima-nova/proxima-nova_regular.ttf') format("truetype"),
         url('../fonts/proxima-nova/proxima-nova_regular-webfont.woff2') format('woff2'),
		 url('../fonts/proxima-nova/proxima-nova_regular-webfont.woff') format('woff');
}


/* =======================================================================
Variables
========================================================================== */

// Colors
$main-color: 			  #ff0000;
$red: 			 		  #d72b38;
$black:					  #171717;
$grey:					  #989898;
$grey-light:			  #f1f1f1;
$grey-dark:			  	  #292929;
$white:					  #fcfcfc;

// Fonts stacks
$font-stack:              Arial, Verdana, sans-serif;
$font-stack-alt:          Georgia, serif;
$font-size-base:          16px;
$line-height-base:        1.4;

// Typography
$color-text:              #333;
$color-link:              $main-color;
$color-link-visited:      lighten($color-link, 20%);
$color-link-hover:        darken($color-link, 20%);
$color-link-active:       darken($color-link, 40%);

// Fonts
$p-black:				  'proxima-black';
$p-bold:				  'proxima-bold';
$p-regular:				  'proxima-regular';