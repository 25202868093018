.c-error {
	padding:125px 40px;
	display:flex;
	flex-flow:column nowrap;
	justify-content:center;
	align-items:center;
	margin:20px 20px 0px;
	background:$grey-light;

	&__number {
		font-family: $p-black;
		font-size: 140px;
		line-height: 120px;
		color:$red;
	}

	h1 {
		font-family: $p-black;
		text-transform: uppercase;
		margin-bottom:5px;
	}

	&__why {
		font-family: $p-regular;
		font-size:1.6rem;
		text-align:center;
		color:#9d9d9d;
		margin:8px 0px;

		span {
			font-size:1.8rem;
			display: block;
			color:#333;
		}
	}
}