.c-frame-border--top,
.c-frame-border--bottom {
	@extend .c-frame-border;
}

.c-frame-border {
	position:fixed;
	left:0px;
	right:0px;
	height:20px;
	width:100%;
	background:white;
	z-index: 9999;
}

.c-frame-border--top {
	top:0px;
}

.c-frame-border--bottom {
	bottom:0px;
}