.c-page-header {
		position:relative;

	&:before {
		content: "";
		position: absolute;
		top: 0;
		left: 20px;
		right: 20px;
		bottom: -30px;
		margin: auto;
		background-image: url(../images/dot-pattern.png);
		background-size: cover;
		background-repeat: no-repeat;
		opacity: 0.1;
	}

	&__container {
		max-width:840px;
		padding:80px 20px 60px;
		
		margin:0 auto;
		display:flex;
		flex-flow:row wrap;
		justify-content:space-between;
		align-items:center;
		position:relative;
		z-index: 9;

		@include mq('small') {
			flex-flow:column wrap;
			padding:40px 20px;
			justify-content: center;
			text-align: center;
		}
		
		& > div {
			width:50%;

			@include mq('small') {
				width:100%;
			}
		}
	}



	h1 {
		font-family: $p-black;
		font-size: 4.5rem;
		line-height: 5.2rem;
		color:$black;
		text-transform: uppercase;
		position:relative;
		z-index:1;

		@include mq('small') {
			margin-bottom:0px;
		}

		&:before {
			content:'';
			position:absolute;
			left:-50px;
			top:0px;
			bottom:0px;
			margin:auto 0;
			width:175px;
			height:30px;
			background:$red;
			transform:rotate(-45deg) skewX(45deg);
			z-index: -1;

			@include mq('small') {
				display: none;
			}
		}
	}

	&__punchline {

		p {
			font-family: $p-black;
			font-size:2.4rem;
			line-height: 2.8rem;
			color:$red;
			text-transform:uppercase;
			max-width:300px;
			margin-left:auto;

			@include mq('small') {
				text-align: center;
				max-width:100%;
			}
		}
	}  
}