.c-button--lift-up,
.c-button--rectangle,
.c-button--fournisseurs,
.c-button--footer  {
	@extend .c-button;
}

.c-button {
	font-family:$p-black !important;
	font-size:1.4rem;
	line-height:1.8rem;
	color:$white;
	text-transform:uppercase;
	position:relative;
	text-align:center;
	z-index: 1;
	cursor: pointer;
	display:inline-flex;
	flex-flow:row wrap;
	align-items:center;

	&:visited {
		color:#fff;
	}

	&--lift-up {
		display: block;
		width:100%;
		padding:30px;

		@include mq('xsmall') {
			padding:15px 20px;
		}

		&:after {
			content:'';
			position:absolute;
			bottom:0px;
			width:100%;
			height:10px;
			left:0px;
			right:0px;
			margin:0 auto;
			background:$red;
			z-index: -1;
			transition:all 300ms ease-in-out;
			transition-delay:300ms;
		}

		i {
			transition:all 300ms ease-in-out;
			font-size:1.8rem;
			transition-delay: 0ms;
			color:$black;
			opacity:0;
			margin-left:10px;
			margin-top:-2px;

			@include mq('small') {
				display:none;
			}
		}
	}

	&--rectangle {
		background:$black;
	    padding: 18px 35px;
  		margin-top: 25px;

  		i {
  			color:$red;
  			margin-left:10px;
  			transition:all 300ms ease-in-out;
  			margin-top:-2px;
  		}

  		&:hover {
			color:$white;

  			i {
  				margin-left:20px;
  			}
  		}
	}

	&--fournisseurs {
		padding:20px 30px;
		margin:10px auto 0px;
		color:$white;
		font-size:1.2rem;
		position:relative;
		transition:all 300ms ease-in-out;

		@include mq('medium') {
			padding:16px 25px;
			font-size:1.4rem;
		}

		&:before {
			content:'';
			position:absolute;
			bottom:0px;
			left:0px;
			right:0px;
			margin:0 auto;
			height:4px;
			background:$red;
			transition:all 300ms ease-in-out;
			z-index: -1;
			transition-delay:300ms;
		}

		&:hover {
			color:$white !important;

			&:before {
				height:100%;
				transition-delay: 0ms;
			}

			i {
				margin-left:30px;
				color:$black;
				transition-delay:300ms;
			}
		}

		i {
			margin-left:10px;
			color:$red;
			transition:all 300ms ease-in-out;
			transition-delay:0ms;
			font-size:1.6rem;
			margin-top:-2px;

			@include mq('small') {
				display: none;
			}
		}
	}

	&--footer {
		color:$white;
		transition:all 300ms ease-in-out;

		&:hover {
			color:$red;

			i {
				margin-left:30px;
			}
		}
		
		i {
			color:$red;
			margin-left:10px;
			transition:all 300ms ease-in-out;
			margin-top:-2px;
		}

		&.single-website {
			margin-top:20px;
		}
	}
}