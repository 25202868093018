.c-form {

	&__container {
		background-color:$black;
		background-image:url('../images/contact-form_bg.png');
		background-size:cover;
		background-repeat:no-repeat;
		background-position:center;
		padding:120px 40px;
		
		text-align: center;

		@include mq('small') {
			padding:60px 30px;
		}
	}

	&__title {
		position:relative;
		z-index: 1;
		margin-bottom:60px;

		@include mq('small') {
			margin-bottom:20px;
		}

		&:before {
			content:'';
			position:absolute;
			top:0px;
			right:0px;
			bottom:0px;
			left:0px;
			margin:auto;
			width:120px;
			height:30px;
			background-color:$red;
			transform:rotate(-45deg) skewX(45deg);
			z-index: -1;

			@include mq('small') {
				display: none;
			}
		}
	}

	h2 {
		font-family: $p-black;
		font-size:3.2rem;
		line-height: 3.2rem;
		color:$white;
		text-transform:uppercase;
		margin-bottom:5px;
		margin-top:0px;
	}

	h3 {
		font-family: $p-black;
		font-size:1.8rem;
		line-height: 1.8rem;
		color:$white;
		text-transform:uppercase;
		margin-bottom:0px;
		margin-top: 0px;

		@include mq('small') {
			color:$red;
		}
	}

	.wpcf7 {
		margin-top:40px;
		max-width:600px;
		margin:0 auto;
	}

}

.c-form__wrapper {
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	align-items:center;

	span {
		&:first-of-type,
		&:nth-of-type(4) {
			width:100%;
		}

		&:nth-of-type(2),
		&:nth-of-type(3) {
			width:calc(50% - 20px);

			@include mq('xsmall') {
				width:100%;
				padding:0px !important;
			}
		}

		&:nth-of-type(2) {
			padding-right:20px;
		}

		&:nth-of-type(3) {
			padding-left:20px;
		}
	}

	br {
		display: none;
	}
}

.c-form__captcha {
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	width: 100%;
	margin-top: 30px;
}


.c-form__textbox,
.c-form__textarea {
	background:transparent;
	border-top:0px;
	border-left:0px;
	border-right:0px;
	border-bottom:2px solid $grey;
	font-family:$p-regular;
	font-size:1.5rem;
	color:$white;
	width:100%;
	margin-top:30px;
	padding:15px 20px;
	

	@include mq('xsmall') {
		font-size:1.8rem;
		text-align: center;
	}
}

.c-form__textarea {
	height:150px;
}

.c-form__submit {
	position:relative;
	display:inline-block;
	margin:40px auto 0px;

	input {
		border:0px;
		background:transparent;
		font-family:$p-black;
		font-size:1.5rem;
		text-transform:uppercase;
		color:$white;
		padding:20px 70px 20px 30px;
		position:relative;
		z-index: 2;
		cursor:pointer;

		@include mq('xsmall') {
			padding:20px 30px;
		}

	}

	&:before {
		content:'';
		position:absolute;
		left:0px;
		right:0px;
		bottom:0px;
		width:100%;
		height:3px;
		background-color:$red;
		margin:0 auto;
		transition:all 300ms ease-in-out;
		z-index: 0;
	}

	&:after {
	    content: "\f178";
		display: inline-block;
		font: normal normal normal 14px/1 FontAwesome;
		font-size: inherit;
		text-rendering: auto;
		-webkit-font-smoothing: antialiased;
		position:absolute;
		right:30px;
		top:22px;
		margin:auto 0;
		color:$red;
		transition:color 300ms ease-in-out;

		@include mq('xsmall') {
			display: none;
		}
	}

	&:hover {

		&:before {
			height:100%;
		}

		&:after {
			color:$black;

		}
	}
}

.c-form__textbox,
.c-form__textarea,
.c-form__submit input {

	&:focus {
		outline:0;
	}
}

.screen-reader-response {
	display:none;
}


.wpcf7-form-control-wrap {
	position:relative;
	text-align:left;
}

.wpcf7-not-valid-tip {
	color:$red;
	font-size:1.2rem;
	padding:0px 25px;
	margin-top:10px;
	display:block;

}

.wpcf7-mail-sent-ok,
.wpcf7-validation-errors {
	font-family:$p-regular;
	font-size:1.4rem;
	margin-top:30px;
}

.wpcf7-mail-sent-ok {
	color:#7ae651;
}

.wpcf7-validation-errors {
	color:$red;
}