.c-mobile__nav {
	position: fixed;
	top: 0px;
	left: 0px;
	transform: translate(-100%, 0px);
	background: #000;
	width: 100%;
	max-width: 400px;
	height: 100%;
	transition: all 300ms ease-out;
	z-index: 9999999;
	padding:30px;
	box-sizing:border-box;
	display:flex;
	flex-flow:column nowrap;
	justify-content:space-between;
	
	&.active {
		transform: translate(0%, 0px);
	}
}

.js-menu-button {
	display:none;
	margin-bottom:0px;
	position:relative;
	z-index: 9999;

	i {
		color:white;
		transition:all 500ms ease-out;
	}

	&:hover i {
		color:white;
	}

	@include mq('medium') {
		display:block;
	}

	@include mq('xsmall') {
		margin-bottom:20px;
		position: absolute;
	    bottom: 0px;
	    right: 0px;
	    background: #d72b38;
	    margin-bottom: 0px;
	    padding: 15px 20px;
	}
}

.js-mobile-nav {
	overflow:hidden;

	&:before {
		display:block;
		height:100%;
		width:100%;
		position:absolute;
		transform:rotate(45deg);
		opacity: 0.15;
		pointer-events: none;
		width:150%;
		height:150%;
		top:-100px;
	}

	.c-mobile__logo {

		img {
			max-width:160px;
			height: auto;
			width:100%;
		}
	}

	.c-mobile__menu {

		ul {
			display:flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items: flex-start;
			height: 100%;
			list-style-type: none;
			padding-left:0px;

			li {

				a {
					font-size:20px;
					line-height: 26px;
					margin-bottom:20px;
					display: block;
					font-family:$p-black;
					color:white;
					transition:all 300ms ease-in-out;
					text-transform:uppercase;

					&:hover {
						color:$red;
					}
				}


				&.current-menu-item a:before {
					width:20px;
				}
			}
		}
	}
}

.js-menu-button.close {
    position: absolute;
    top:30px !important;
    right: 30px !important;
    bottom:initial !important;
    background: transparent;
    color: #fff;
    border:0px;
    font-size:14px;

    &:focus {
		outline:0;
    }
}

.c-mobile__phone {
	font-family:$p-black;
	letter-spacing:1px;
	font-weight:bold;
	color:white;
	text-transform:uppercase;
	font-size:13px;
	-webkit-font-smoothing: antialiased;

	a {
		color:$red;
		margin-right:5px;
	}
}