.c-services {
	display:flex;
	flex-flow:row wrap;
	position:relative;
	z-index: 9;

	&__left,
	&__right {
		width:50%;
		flex:1 1 auto;
		min-height:500px;

		@include mq('medium') {
			width:100%;
			min-height:400px;
		}

		@include mq('xsmall') {
			min-height:300px;
		}
	}

	&__left {
		display: block;
		background-size:cover;
		background-repeat:no-repeat;
		background-position:center;
	}

	&__right {
		background:$red;
		display: flex;
		flex-flow:column wrap;
		align-items: flex-start;
		justify-content: center;
		padding:40px 60px;
		
		overflow: hidden;
		position:relative;
		z-index: 0;

		@include mq('medium') {
			text-align: center;
			align-items:center;
		}

		@include mq('xsmall') {
			padding:40px 20px;
		}

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:cover;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 1;
			transform:rotate(180deg);
			opacity: 0.5;
		}
	}

	h2 {
		font-family: $p-black;
		font-size:3rem;
		line-height: 3.5rem;
		color:$white;
		text-transform:uppercase;
		margin:10px auto 0px 0px;
		max-width:465px;

		@include mq('medium') {
			margin:10px auto;
		}

		@include mq('xsmall') {
			font-size:2.5rem;
			line-height: 3rem;
		}
	}

	h2, span, .c-button {
		position:relative;
		z-index: 2;
	}

	&__dispatch {
	    width: 100%;
	    display: flex;
	    flex-flow: row wrap;
	    justify-content: space-around;
	    position:relative;
	    z-index: 0;
		background:$black;

		@include mq('small') {
			flex-flow:column wrap;
		}

	    a {
	    	flex:1 1 auto;
	    	width:50%;
	    	text-align: center;
	    	padding:40px 30px;
	    	position:relative;
	    	font-family: $p-black;
	    	font-size:1.8rem;
	    	color:$white;
	    	text-transform: uppercase;
	    	display: inline-flex;
		    flex-flow: row wrap;
		    justify-content: center;
		    align-items: center;
		    z-index: 5;
		    cursor:pointer;

		    &:before {
		    }

    		@include mq('medium') {
				flex-flow:column nowrap;
				align-items:center;
				justify-content: center;
				flex:0 0 auto;
				width: 100%;

				img {
					margin-bottom:10px;
					margin-right:0px !important;
				}
			}

		    &:hover {
		    	
		    	&:before {
					height:100%;
		    	}

		    	img {
		    		filter:brightness(0);
		    	}
		    }

		    &:before {
		    	content:'';
		    	position:absolute;
		    	bottom:0px;
		    	left:0px;
		    	right:0px;
		    	height:6px;
		    	background:$red;
		    	display: block;
		    	transition:all 300ms ease-in-out;
		    	z-index: -1;

		    }

	    	img {
	    		width: 40px;
	    		height:40px;
	    		display:block;
	    		margin-right: 20px;
	    		transition:all 300ms ease-in-out;
	    	}
	    }
	}

	&__fournisseurs {
		width:100%;
		background-color:$black;
		background-image:url(../images/fournisseur.png);
		background-repeat:no-repeat;
		background-size:70%;
		background-position:center;
		position:relative;

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:cover;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 1;
			transform:rotate(180deg);
			opacity: 0.7;
		}

		&:after {
			content:'';
			position:absolute;
			top:2px;
			right:0px;
			bottom:0px;
			left:0px;
			margin:auto;
			height:2px;
			background:$grey-dark;
			width:100%;

			@include mq('small') {
				display:none;
			}
		}

		ul {
			list-style-type:none;
			padding-left:0px;
			display: flex;
			flex-flow:row wrap;
			margin:0 auto;
			position:relative;
			z-index: 10;

			li {
				width: 25%;
				padding:75px 35px 0px;
				
				display: flex;
				flex-flow:column wrap;
				justify-content: center;
				align-items:center;
				position:relative;

				@include mq('medium') {
					width: calc(100% / 3);
					padding:75px 25px 0px;
				}

				@include mq('small') {
					width:50%;
					padding:75px 35px 0px;
				}

				@include mq('small') {
					width:100%;
				}

				.fournisseurs-logo {
					height:100px;
					display: flex;
					flex-flow:column wrap;
					justify-content: center;
					align-items:center;

					img {
						max-width:150px;
					}
				}

				p {
					text-align:center;
					font-size:1.4rem;
					line-height: 1.8rem;
					color:$grey;
					width:100%;

					@include mq('small') {
						font-size:2.1rem;
						line-height: 2.8rem;
					}
				}
			}
		}
	}
}

.c-services__listing {
	display:flex;
	flex-flow:row wrap;

	@include mq('small') {
		flex-flow:column wrap;
	}

	& > div {
		width:50%;

		@include mq('small') {
			width:100%;
		}

		&.c-services__image:nth-child(2) {
			
			@include mq('small') {
				order:-1;
			}
		}
	}

	h2 {
		font-family: $p-black;
		font-size:2.6rem;
		line-height: 3rem;
		color:$white;
		margin-top:0px;
		text-transform:uppercase;
		margin:0px;
	}

	p {
		margin-bottom:30px;
	}

	.c-button {
		margin-top:0px !important;
	}
}

.c-services__item {
	background:$red;
	padding:80px 60px;
	

	@include mq('small') {
		text-align:center;
		padding:60px 30px;
	}
}

.c-services__image {
	background-size:cover;
	background-position:center;
	background-repeat:no-repeat;

	@include mq('small') {
		height:300px;
	}
}

.c-services__autres {
	background:$grey-light;
	width:100% !important;
	padding:100px 60px 60px;
	display:flex;
	flex-flow:row wrap;
	

	@include mq('small') {
		flex-flow:column wrap;
		padding:60px 30px;
	}

	& > h2 {
		width:42%;
		margin-right:8%;
		font-size:3rem;
		line-height: 3.4rem;
		color:$black;

		@include mq('small') {
			width:100%;
			margin-right:0px;
			margin-bottom:60px;
			text-align: center;
		}
	}

	& > ul {
		padding:0px;
		list-style-type:none;
		justify-content: space-between;
		align-items:flex-start;
		margin-top:0px;
		width:50%;


		@include mq('small') {
			width:100%;
		}

		li {

			h3 {
				margin-top:0px;
				margin-bottom:0px;
				font-family:$p-black;
				text-transform: uppercase;
				color:$black;
				position:relative;
				z-index: 1;
				font-size:2.6rem;
				line-height: 3rem;

				@include mq('small') {
					text-align: center;
					margin-bottom:15px;
				}

				@include mq('xsmall') {
					color:$red;
				}

				&:before {
				    content: "";
				    display: block;
				    width: 60px;
				    height: 15px;
				    background: #d72b38;
				    transform: rotate(-45deg) skewX(45deg);
				    position:absolute;
				    top:0px;
				    left:-30px;
				    z-index: -1;

				    @include mq('small') {
				    	left:0px;
				    	right:0px;
				    	top:0px;
				    	bottom:0px;
				    	margin:auto;
				    }

				    @include mq('xsmall') {
						display: none;
				    }
				}
			}

			p {
				color:$grey;
				font-size:1.4rem;
				line-height: 2.2rem;
				margin-top:10px;
				margin-bottom:40px;

				@include mq('small') {
					font-size:2rem;
					line-height:2.4rem;
					text-align: center;
				}
			}
		}
	}
}