/* =======================================================================
Helpers
========================================================================== */

.h-centered {
	text-align: center;
}

.h-clearfix:before,
.h-clearfix:after {
    content: " "; /* 1 */
    display: table; /* 2 */
}

.h-clearfix:after {
    clear: both;
}

.h-clearfix {
    *zoom: 1;
}

.h-unselectable {
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;  
}

.h-visuallyhidden {
	border: 0;
	clip: rect(0 0 0 0);
	height: 1px;
	margin: -1px;
	overflow: hidden;
	padding: 0;
	position: absolute;
	width: 1px
}

.h-embed-responsive{
	position: relative;
	display: block;
	height: 0;
	padding: 0;
	overflow: hidden
}

.h-embed-responsive iframe,
.h-embed-responsive embed,
.h-embed-responsive object,
.h-embed-responsive video{
	position: absolute;
	top: 0;
	left: 0;
	bottom: 0;
	height: 100%;
	width: 100%;
	border: 0
}

.h-embed-responsive-16by9{
	padding-bottom: 56.25%;
}

.h-embed-responsive-4by3{
	padding-bottom: 75%;
}












