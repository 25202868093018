.c-fold {
	height:calc(100% - 170px);
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	align-items:flex-start;
	padding-left:20px;

	@include mq('medium') {
		height:auto;
	}

	&:before {
		content:'';
		position:absolute;
		top:20px;
		bottom:0;
		left:20px;
		width:300px;
		display: block;
		height:calc(100% + 60px);
		background-color:$black;

		@include mq('medium') {
			display: none;
		}
	}

	&:after {
	    content: "";
	    position: absolute;
	    bottom: 0;
	    top: 20px;
	    left: 20px;
	    right: 0;
	    background-image: url(../images/dot-pattern.png);
	    background-size: cover;
	    background-position: center right;
	    background-repeat: no-repeat;
	    max-width: 300px;
	    height:calc(100% + 60px);
	    opacity: 0.4;

		@include mq('medium') {
			display: none;
		}
	}
		
	&__col-left,
	&__col-right {
		height:100%;

		@include mq('medium') {
			
		}
	}

	&__col-left {
		width:calc(48% - 10px);
		display:flex;
		flex-flow:column nowrap;
		justify-content: center;
		align-items:flex-start;
		margin-right:10px;

		@include mq('medium') {
			width:100%;
			margin-right:0px;
			height:400px;
		}
	}

	&__col-right {
		width:calc(52% - 10px);
		margin-left:10px;
		display:flex;
		flex-flow:row wrap;
		justify-content: center;
		align-items:flex-start;

		@include mq('medium') {
			justify-content: space-between;
			margin-left:0px;
			width:100%;
			height:50%;
		}
	}

	&__about {
		//background-image:url('../images/store.jpg');
		background-repeat:no-repeat;
		background-size:cover;
		background-position:center;
		width:100%;
		height:100%;
		position:relative;
		z-index: 1;
		display: flex;
		flex-flow:column wrap;
		justify-content: flex-end;
		align-items:flex-start;
		padding:40px 60px 0px;
		
		cursor:pointer;

		@include mq('medium') {
			padding-top:80px;
		}

		@include mq('xsmall') {
			padding:40px 30px 0px;
		}

		&:before {
			content:'';
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			background: rgba(0,0,0,.65);
			z-index: 2;
			pointer-events:none;
			transition:background 300ms ease-in-out;
		}

		&:hover {

			&:before {
				background:rgba(0,0,0,0.8);
			}

			.c-button {

				&:after {
					height:100%;
					transition-delay:0ms;
				}

				i {
					margin-left:30px;
					transition-delay:300ms;
					opacity:1;
				}
			}
		}
		
		&  > div {
			z-index: 2;
			display: flex;
			flex-flow:row wrap;
			justify-content: center;
			align-items:center;
		}

		.c-fold__content {
			flex-grow:1;
			display: flex;
			flex-flow:column wrap;
			justify-content: center;
			align-items:flex-start;
			width:100%;

			@include mq('medium') {
				text-align: center;
				align-items:center;
			}
		}


		h2, span, p {
			z-index: 3;
		}

		h2 {
			max-width: 500px;
			font-size:4.5rem;
			line-height: 5rem;

			@include mq('xsmall') {
				font-size:4.2rem;
				line-height: 4.5rem;
			}
		}

		p {
			max-width:450px;

			@include mq('medium') {
				margin-bottom:40px;
			}
		}
	}

	&__promo {
		display:flex;
		flex-flow:column wrap;
		justify-content: flex-start;
		align-items:flex-start;
		background:$red;
		padding:25px 30px;
		position:relative;
		z-index: 9;
		width:100%;
		transition:all 300ms ease-in-out;

		@include mq('medium') {
			margin-top:20px;
		}

		@include mq('small') {

			img {
				max-width:180px !important;
			}
		}

		@include mq('xsmall') {
			align-items:center;

			img {
				display: none;
			}
		}

		&:hover {
			background: darken($red, 10);
		}

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:100%;
			background-position:top left;
			background-repeat:no-repeat;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 1;
			transform:scaleX(-1);
		}

		&:after {
			content: "";
			position: absolute;
			top: 40px;
			right: 0px;
			display: block;
			height: 30px;
			width: 155px;
			background: #171717;
			transform: rotate(-45deg) skewX(45deg);

			@include mq('xsmall') {
				display: none;
			}
		}

		h2, span {
			position:relative;
			z-index: 2;
		}

		h2 {
			max-width:300px;
			font-size:3rem;
			line-height:3rem;

			@include mq('small') {
				max-width: 200px;
			}

			@include mq('xsmall') {
				margin:0 auto;
				text-align: center;
			}
		}

		img {
			position: absolute;
			right: 20px;
			top: 40px;
			bottom: 0;
			margin: auto 0;
			max-width: 210px;
			z-index: 3;
			backface-visibility: hidden;
		}
	}

	h2, h3 {
		font-family: $p-black;
		color:$white;
		margin-top:0px;
		margin-bottom:0px;
		text-transform: uppercase;
	}


	h3 {		
		font-size:1.8rem;
		line-height: 2.6rem;
	}

	&__commercial,
	&__products {
		width:calc(50% - 10px);
		height: 100%;
		display: flex;
		flex-flow:column wrap;
		justify-content: center;
		align-items:center;
		margin-top:20px;
		padding:30px;
		
		text-align: center;

		@include mq('medium') {
			height:auto !important;
			height:300px !important;
		}

		@include mq('small') {
			width:100%;
			margin-left:0px !important;
			margin-right:0px !important;
			height:250px !important;
		}

		&:hover {

			h3 {

				&:after {
					transform:translateX(0px);
					opacity: 1;
				}
			}
		}

		h3 {
			max-width:220px;
			position:relative;

			@include mq('xsmall') {
				max-width: 100%;
			}

			&:after {
				content:"\f178";
				display: block;
				font: normal normal normal 14px/1 FontAwesome;
				font-size: inherit;
				text-rendering: auto;
				-webkit-font-smoothing: antialiased;
				-moz-osx-font-smoothing: grayscale;
				color:$red;
				position:absolute;
				bottom:-30px;
				left:0px;
				right:0px;
				margin:0 auto;
				opacity: 0;
				transform:translateX(-25px);
				transition:all 300ms ease-in-out;

			}
		}
	}

	&__commercial {
		margin-right:10px;
		//background-image:url('../images/commercial.jpg');
		background-size:cover;
		background-repeat:no-repeat;
		background-position: 80% 0px;
		position:relative;
		cursor: pointer;

		@include mq('medium') {
			background-position:center;
		}

		&:hover {

			&:before {
				background:rgba(0,0,0,0.8);
			}
		}

		&:before {
			content:'';
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			background: rgba(0,0,0,.65);
			z-index: 2;
			pointer-events:none;
			transition:background 300ms ease-in-out;
		}

		h3, span {
			position:relative;
			z-index: 3;
		}
	}

	&__products {
		margin-left:10px;
		background-color:$black;
		position:relative;

		&:before {
		    content: '';
		    position: absolute;
		    bottom: 0;
		    top: 0;
		    left: 0;
		    right: 0;
		    background-image: url(../images/dot-pattern.png);
		    background-size: cover;
		    background-position: center right;
		    background-repeat: no-repeat;
		    opacity: 0.5;
		}

		h3, span {
	    	position: relative;
	    	z-index: 3;
	    }

	}

	&__partners {
		position: absolute;
	    top: 0;
	    right: 0;
	    bottom: 0;
	    left: 0;
	    display: flex;
	    flex-flow: column wrap;
	    justify-content: center;
	    align-items: center;
	    opacity: 0.4;
	    pointer-events:none;

	    @include mq('medium') {
	    	display: none;
	    }


	    img {
	    	height: 15px;
	    	margin: 8px 5px;
	    	opacity: 1;
	    }

	    & > div {
	    	max-width: 230px;
	    }

	    & > .top-row {
	    	margin-top: 270px;
	    	height: 100px;
	    }

	    & > .bottom-row {
	    	margin-top: 20px;
	    }
	}
}