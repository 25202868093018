.c-header {
	background-color:$black;
	display:flex;
	flex-flow:row wrap;
	justify-content:space-between;
	align-items:center;
	height:110px;
	position:relative;
	overflow:hidden;
    margin: 20px 20px 0px;
    padding-right:0px;

    @include mq('xsmall') {
		flex-flow:column wrap;
		height:auto;
		padding-left:0px;
    }

	&:before {
		content:'';
		background-image:url(../images/dot-pattern.png);
		background-size:100%;
		background-position:bottom -250px right;
		position:absolute;
		top:0;
		right:0;
		bottom:0;
		left:0;
		margin:0 0 0 auto;
		pointer-events: none;
		transform:translateX(10%);
		max-width: 1000px;
	}

	& > div {
		position:relative;
		z-index: 1;

		@include mq('xsmall') {
			width:100%;
		}
	}

	&__logo {
		flex-grow:1;

		@include mq('xsmall') {
			padding:40px 40px 20px;
			text-align: left;
			margin-bottom:10px;
		}

		img {
			max-width:220px;
			width:100%;
			margin-left:20px;
			margin-top:20px;

			@include mq('xsmall') {
				margin-left:0px;
				max-width:80%;
			}
		}
	}

	&__menu {

		@include mq('medium') {
			display: none;
		}

		ul {
			display:flex;
			flex-flow:row wrap;
			justify-content:space-between;
			align-items:center;
			list-style-type:none;
			padding:0px;
			margin:0px;

			& > li > a {
				font-family:$p-black;
				font-size:1.3rem;
				line-height: 2rem;
				color:$grey;
				text-transform:uppercase;
				margin:0 2.5rem;
				transition:color 300ms ease-in-out;
				position:relative;

				@include mq('large') {
					margin: 0 1.5rem;
				}

				&:after {
					content:'';
					display:block;
					width:0%;
					height:3px;
					position:absolute;
					bottom:-10px;
					left:0px;
					background:$red;
					transition:all 300ms ease-in-out;
				}
			}

			& > li:hover > a,
			& > li.current_page_item > a {
				color:$white;

				&:after {
					width:100%;
				}
			}
		}
	} 

	&__info {
		height:100%;
		margin-left:2.5rem;
		display: flex;
	    flex-flow: column wrap;
	    justify-content: space-around;
	    align-items: center;

	    @include mq('xsmall') {
	    	margin-left:0px;
	    }

	    & > div {
	    	width:100%;
	    	height:50%;
	    	display:flex;
	    	flex-flow:column wrap;
	    	justify-content: center;
	    	align-items:center;
	    	padding:1.5rem 2rem;
	    	text-align: center;

	    	@include mq('xsmall') {
	    		align-items:flex-start;
	    	}
	    	
	    }
	}

	&__financing,
	&__phone {
		font-family: $p-black;
		color:$white;
	}

	&__financing {
		background:$red;
		font-size:1rem;
		line-height: 1.8rem;
		text-transform:uppercase;

		@include mq('medium') {
			font-size:1.4rem;
			line-height: 2rem;
		}

		@include mq('small') {
			font-size:1.6rem;
			line-height: 2rem;
		}

		@include mq('xsmall') {
			display: none !important;
		}
	}

	&__phone {
		border-left:3px solid $red;
		font-size:1.4rem;
		line-height: 1.8rem;

		@include mq('medium') {
			font-size:1.4rem;
			line-height: 2rem;
		}
		
		@include mq('small') {
			font-size:2.4rem;
			line-height: 2.4rem;
		}

		@include mq('xsmall') {
			border-left:0px;
			padding:20px 55px 20px 40px !important;
		}

		a {
			color:$white;
			display:flex;
			flex-flow:row wrap;
			justify-content: flex-start;
			align-items:center;
		}

		i {
			color:$red;
			font-size:1.8rem;
			margin-right:5px;
		}
	}
}