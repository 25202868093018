.c-contact {
	display:flex;

		@include mq('small') {
			flex-flow:column wrap;
		}


	&__info-wrapper {
		position:relative;
		z-index: 2;
		padding:40px 30px;
	}

	&__map {
		width:66.6666%;
		position:relative;

		.c-map {
			width:100%;
			height:100%;
			top:0;
			right:0;
			bottom:0;
			left:0;
			position:absolute;
		}

		@include mq('small') {
			width:100%;
			height:250px;
		}
	}

	&__info {
		width:33.3333%;
		background:$black;
		text-align:center;
		
		position:relative;

		@include mq('small') {
			width:100%;
		}

		&:before {
			content:'';
			background-image:url(../images/dot-pattern.png);
			background-size:cover;
			background-position:left center;
			position:absolute;
			top:0;
			right:0;
			bottom:0;
			left:0;
			pointer-events: none;
			z-index: 1;
			transform:rotate(180deg);
		}

		&:after {
			content:'';

		}

		h2 {
			font-family:$p-black;
			font-size:1.8rem;
			line-height: 2.4rem;
			color:$white;
			text-transform:uppercase;
			position:relative;

			@include mq('small') {
				font-size:2.4rem;
				line-height: 2.8rem;
			}

			&:after {
				content:'';
				display:block;
				width:90%;
				height:2px;
				margin:30px auto;
				background: -moz-linear-gradient(left, rgba($grey,0) 0%, rgba($grey,0) 1%, rgba($grey,0.3) 50%, rgba($grey,0) 100%);
				background: -webkit-linear-gradient(left, rgba($grey,0) 0%,rgba($grey,0) 1%,rgba($grey,0.3) 50%,rgba($grey,0) 100%);
				background: linear-gradient(to right, rgba($grey,0) 0%,rgba($grey,0) 1%,rgba($grey,0.3) 50%,rgba($grey,0) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00292929', endColorstr='#00292929',GradientType=0 );
			}
		}

		h3 {
			font-family: $p-black;
			font-size: 1.4rem;
			line-height:1.8rem;
			color:$red;
			text-transform: uppercase;
			margin-bottom:5px !important;
			margin-top:25px;

			@include mq('small') {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}

		p, a, li {
			font-family:$p-regular;
			font-size:1.5rem;
			line-height: 2.2rem;
			color:$white;

			@include mq('small') {
				font-size: 1.8rem;
				line-height: 2.4rem;
			}
		}

		p {
			margin-top:0px;
		}

		ul {
			padding-left:0px;
			list-style-type:none;
			margin-bottom:25px;
			margin-top:0px;
		}
	}
}

.c-contact__360 {
	position:relative;

	&-badge {
		font-family:$p-black;
		font-size:3.2rem;
		line-height: 3.6rem;
		color:$white;
		background:$red;
		padding:12px 30px;
		display: inline-block;
		position:absolute;
		bottom:6px;
		left:20px;
		z-index: 999;
	}

	iframe {
		width:100%;
		height:500px;
		pointer-events:none;
		position:relative;

		&.clicked {
			pointer-events:auto;
		}

		@include mq('small') {
			height:400px;
		}

	}
}