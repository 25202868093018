html {
	font-size:10px;

	@media screen and (min-width:1400px) {
		font-size:12px;
	}

	@include mq('medium') {
		font-size:8px;
	}

	@include mq('xsmall') {
		font-size:7px;
	}
}

* {
	box-sizing:border-box;
}

html,
button,
input,
select,
textarea {
	color: $color-text;
	font-family: $font-stack;
}

body {
	background: #fff;
	font-size: $font-size-base;
	line-height: $line-height-base;
	margin:0px;
}

html, body {
	height:100%;
}

header, section, footer {
	padding-left:20px;
	padding-right:20px;
	box-sizing:border-box;
}

section, footer {
	padding-top:20px;
}

h1, h2, h3, h4, h5, h6, a, span, div, li {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

p {
	font-family:$p-regular;
	font-size:1.5rem;
	line-height:1.8rem;
	color:$white;

	@include mq('small') {
		font-size:2rem;
		line-height: 2.4rem;
	}
}

a {
	color: $color-link;
	text-decoration: none;
	&:hover { color: $color-link-hover; }
	&:focus,
	&:active { color: $color-link-active; }
}

img { vertical-align: middle; max-width: 100%; }

hr {
	display: block;
	height: 1px;
	border: 0;
	border-top: 1px solid #ccc;
	margin: 1em 0;
	padding: 0;
}

fieldset {
	border: 0;
	margin: 0; padding: 0;
}

textarea {
	resize: vertical;
}

// Selection
::-moz-selection {
	background: black;
	color: white;
	text-shadow: none;
}

::selection {
	background: black;
	color: white;
	text-shadow: none;
}

// Placeholder color
::-webkit-input-placeholder { /* WebKit browsers */
	color: $white;
	opacity: 1;
}
:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
	color: $white;
	opacity: 1;
}
::-moz-placeholder { /* Mozilla Firefox 19+ */
	color: $white;
	opacity: 1;
}
:-ms-input-placeholder { /* Internet Explorer 10+ */
	color: $white;
	opacity: 1;
}